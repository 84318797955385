import { Injectable } from '@angular/core';
import { HttpService } from '@utility/http/http.service';
import { Observable } from 'rxjs';
import { ResponseResource } from '@utility/http/response-resource';
import { SubscriptionPayment } from './subscription-payment';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    constructor(private readonly httpService: HttpService) {}

    /**
     * Perform delete subscription request.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public delete(): Observable<void> {
        return this.httpService.delete('subscriptions');
    }

    /**
     * Store new subscription.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public store(payload: {
        couponCode?: string;
    }): Observable<ResponseResource<SubscriptionPayment>> {
        return this.httpService.post('subscriptions', payload);
    }
}
