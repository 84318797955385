import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { Move } from '@utility/move/move';
import { of, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivityService } from '@utility/activity/activity.service';
import {
    ActivitiesActionsEnum,
    loadActivitiesFailureAction,
    loadActivitiesSuccessAction,
} from '@action/activities/activities.actions';
import { IndexActivitiesRequestProps } from '@utility/activity/index-activities-request';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';

@Injectable()
export class ActivitiesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly activityService: ActivityService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Load activities effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadActivitiesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActionsEnum.LoadActivities),
            withLatestFrom(
                this.store.select(
                    (state: AppState) =>
                        state.activitiesState.activitiesState.page,
                ),
                this.store.select(
                    (state: AppState) =>
                        state.activitiesState.activitiesState.limit,
                ),
            ),
            mergeMap(
                ([props, page, limit]: [
                    IndexActivitiesRequestProps,
                    number,
                    number,
                ]) =>
                    this.activityService
                        .index({ limit, page, ...props.payload })
                        .pipe(
                            map((response: ResponseResourceCollection<Move>) =>
                                loadActivitiesSuccessAction(response),
                            ),
                            catchError((error: HttpErrorResponse) =>
                                of(loadActivitiesFailureAction({ error })),
                            ),
                        ),
            ),
        ),
    );
}
