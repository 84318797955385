import { NgModule } from '@angular/core';

import { ConfirmationModalComponent } from './confirmation-modal.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, TranslateModule],
    exports: [ConfirmationModalComponent],
    declarations: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
