import {
    loadWorkshopsAction,
    loadWorkshopsFailureAction,
    loadWorkshopsSuccessAction,
    resetWorkshopsAction,
} from '@action/workshops/workshops.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { Workshop } from '@utility/workshops/workshop';

export const workshopsReducer = (
    listState: ListState<Workshop>,
    action: Action,
) =>
    createReducer(
        getInitialListState<Workshop>(),
        on(loadWorkshopsAction, state => baseLoadListReducer(state)),
        on(loadWorkshopsSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(loadWorkshopsFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetWorkshopsAction, () => baseResetListReducer<Workshop>()),
    )(listState, action);
