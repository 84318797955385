import { createReducer, Action, on } from '@ngrx/store';
import {
    resetSessionAction,
    moveOnSessionAction,
    startSessionAction,
    stopSessionAction,
} from '@action/session/session.actions';
import { StartSessionProps } from '@utility/session/start-session-props';
import { SessionState } from '@state/session.state';

const initialSessionState: SessionState = {
    name: null,
    currentMove: null,
    currentMoveIndex: null,
    moveSlugs: [],
    movesCount: null,
    startedAt: null,
    endedAt: null,
    sessionType: null,
    sessionInitializedFrom: null,
    hexColor: null,
};

export const sessionReducer = (sessionState: SessionState, action: Action) =>
    createReducer(
        initialSessionState,
        on(
            startSessionAction,
            (state: SessionState, props: StartSessionProps) => ({
                ...state,
                name: props.payload.name,
                currentMove: props.payload.moves[0],
                currentMoveIndex: 0,
                moveSlugs: props.payload.moves,
                moveIds: props.payload.moveIds,
                movesCount: props.payload.moves.length,
                startedAt: new Date(),
                sessionType: props.payload.sessionType,
                sessionInitializedFrom: props.payload.sessionInitializedFrom,
                hexColor: props.payload.hexColor,
            }),
        ),
        on(stopSessionAction, (state: SessionState) => ({
            ...state,
            currentMove: null,
            currentMoveIndex: null,
            endedAt: new Date(),
        })),
        on(resetSessionAction, () => initialSessionState),
        on(moveOnSessionAction, (state: SessionState) => {
            const currentMoveIndex =
                state.currentMoveIndex +
                (state.currentMoveIndex + 1 >= state.movesCount ? 0 : 1);

            return {
                ...state,
                currentMove: state.moveSlugs[currentMoveIndex],
                currentMoveIndex,
            };
        }),
    )(sessionState, action);
