import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { UnreadMessagesCount } from '@utility/message/unread-messages-count';

export enum MessagesCountActionsEnum {
    LOAD_MESSAGES_COUNT = '[MESSAGES COUNT] Load messages count',
    LOAD_MESSAGES_COUNT_SUCCESS = '[MESSAGES COUNT] Load messages count success',
    LOAD_MESSAGES_COUNT_FAILURE = '[MESSAGES COUNT] Load messages count failure',

    RESET_MESSAGES_COUNT = '[MESSAGES COUNT] Reset messages count',
}

export const loadMessagesCountAction = createAction(
    MessagesCountActionsEnum.LOAD_MESSAGES_COUNT,
);

export const loadMessagesCountSuccessAction = createAction(
    MessagesCountActionsEnum.LOAD_MESSAGES_COUNT_SUCCESS,
    props<ResponseResource<UnreadMessagesCount>>(),
);

export const loadMessagesCountFailureAction = createAction(
    MessagesCountActionsEnum.LOAD_MESSAGES_COUNT_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetMessagesCountAction = createAction(
    MessagesCountActionsEnum.RESET_MESSAGES_COUNT,
);
