import { Injectable } from '@angular/core';
import { HttpService } from '@utility/http/http.service';
import { Observable } from 'rxjs';
import { ResponseResource } from '@utility/http/response-resource';
import { AuthenticationCredentials } from './authentication-credentials';
import { LoginRequestParameters } from './login-request-parameters';
import { RefreshAuthenticationRequestParameters } from './refresh-request-parameters';
import { User } from '../user/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private readonly httpService: HttpService) {}

    /**
     * Make login request.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public login(
        requestParameters: LoginRequestParameters,
    ): Observable<ResponseResource<AuthenticationCredentials>> {
        return this.httpService.post('authentication/login', requestParameters);
    }

    /**
     * Refresh authentication credentials.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public refresh(
        requestParameters: RefreshAuthenticationRequestParameters,
    ): Observable<ResponseResource<AuthenticationCredentials>> {
        return this.httpService.post(
            'authentication/refresh',
            requestParameters,
        );
    }

    /**
     * Check authentication credentials.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public check(): Observable<ResponseResource<User>> {
        return this.httpService.get('authentication/check');
    }
}
