import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '@utility/http/api.service';
import { Activity } from '@utility/activity/activity';
import { StoreActivityRequest } from './store-activity-request';
import { IndexActivitiesRequest } from './index-activities-request';

@Injectable({ providedIn: 'root' })
export class ActivityService extends ApiService<
    Activity,
    IndexActivitiesRequest,
    Params,
    StoreActivityRequest
> {
    protected baseUrl = 'activities';
}
