import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, TranslateModule, RouterModule.forChild([])],
    declarations: [FooterComponent],
    exports: [FooterComponent],
})
export class FooterModule {}
