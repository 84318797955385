import { createAction, props } from '@ngrx/store';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';
import { IndexWorkshopDateProps } from '@utility/workshop-date/index-workshop-dates-request';

export enum WorkshopDatesActionsEnum {
    IndexWorkshopDates = '[WORKSHOPS DATES] Index workshop dates',
    IndexWorkshopDatesSuccess = '[WORKSHOPS DATES] Index workshop dates success',
    IndexWorkshopDatesFailure = '[WORKSHOPS DATES] Index workshop dates failure',

    ResetWorkshopDates = '[WORKSHOPS DATES] Reset workshop dates',

    InsertWorkshopDate = '[WORKSHOPS DATES] Insert workshop date',
    RemoveWorkshopDate = '[WORKSHOPS DATES] Remove workshop date',
}

export const indexWorkshopDatesAction = createAction(
    WorkshopDatesActionsEnum.IndexWorkshopDates,
    props<IndexWorkshopDateProps>(),
);

export const indexWorkshopDatesSuccessAction = createAction(
    WorkshopDatesActionsEnum.IndexWorkshopDatesSuccess,
    props<ResponseResourceCollection<WorkshopDate>>(),
);

export const indexWorkshopDatesFailureAction = createAction(
    WorkshopDatesActionsEnum.IndexWorkshopDatesFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetWorkshopDatesAction = createAction(
    WorkshopDatesActionsEnum.ResetWorkshopDates,
);

export const insertWorkshopDateAction = createAction(
    WorkshopDatesActionsEnum.InsertWorkshopDate,
    props<{ payload: WorkshopDate }>(),
);

export const removeWorkshopDateAction = createAction(
    WorkshopDatesActionsEnum.RemoveWorkshopDate,
    props<{ workshopDateId: number }>(),
);
