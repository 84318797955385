<div
    class="menu"
    [ngClass]="{ 'menu--open': (menuStatus$ | async) === MenuStatus.Open }"
>
    <div class="container">
        <div class="row">
            <div class="col">
                <h2 class="menu__title">
                    {{ 'menu.title' | translate }}

                    <button
                        (click)="closeMenu()"
                        class="menu__close"
                        [attr.aria-label]="'menu.close_menu' | translate"
                    >
                        <img
                            class="menu__close-icon"
                            src="/assets/images/cross-avium.svg"
                            alt=""
                        />
                    </button>
                </h2>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <ul class="menu__list">
                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/home-button.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.home' | translate }}
                        </a>
                    </li>

                    <li
                        *ngIf="authenticatedUser$ | async; let user"
                        class="menu__list-item"
                    >
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'move-buddies']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/move-buddies/move-buddy-{{
                                    user.mentorId
                                }}-subscription.svg"
                                width="30"
                                height="30"
                                alt="Move Buddies"
                            />

                            {{ 'menu.movebuddy' | translate }}
                        </a>
                    </li>

                    <li
                        *ngIf="authenticatedUser$ | async; let user"
                        class="menu__list-item"
                    >
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'tour']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/tour-icon.svg"
                                width="30"
                                height="30"
                                alt="Tour"
                            />

                            {{ 'menu.tour' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'sets']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/sets.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.sets' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'playlists']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/playlists.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.playlists' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'playlists', '1']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/21-moves.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.21moves' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'activities']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/activity.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.activity' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'workshops']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/evenementen.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.workshops' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'messages']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/mail.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{
                                ((unreadMessagesCount$ | async)?.count > 0
                                    ? 'menu.mail_new_messages'
                                    : 'menu.mail'
                                ) | translate
                            }}
                        </a>
                    </li>

                    <li *ngIf="shareAvailable" class="menu__list-item">
                        <a (click)="shareApplication()" class="menu__link">
                            <img
                                class="menu__icon"
                                src="/assets/images/share-icon.svg"
                                width="30"
                                height="30"
                                alt="share app"
                            />

                            {{ 'menu.share' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'settings']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/settings.svg"
                                width="30"
                                height="30"
                                alt="Home"
                            />

                            {{ 'menu.settings' | translate }}
                        </a>
                    </li>

                    <li class="menu__list-item">
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'subscription']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/subscription.svg"
                                width="30"
                                height="30"
                                alt="subscription"
                            />

                            {{ 'menu.subscription' | translate }}
                        </a>
                    </li>

                    <li
                        *ngIf="(authenticatedUser$ | async)?.isAdmin"
                        class="menu__list-item"
                    >
                        <a
                            (click)="closeMenu()"
                            [routerLink]="['/', 'user-payments']"
                            class="menu__link"
                        >
                            <img
                                class="menu__icon"
                                src="/assets/images/wallet-solid.svg"
                                width="30"
                                height="30"
                                alt="subscription"
                            />

                            {{ 'menu.user_payments' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
