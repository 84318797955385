import { Injectable } from '@angular/core';
import { ApiService } from '@utility/http/api.service';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';
import { StoreWorkshopEnlistmentRequest } from '@utility/workshop-enlistment/store-workshop-enlistment-request';
import { Observable } from 'rxjs';
import { ResponseResource } from '@utility/http/response-resource';
import { IndexWorkshopEnlistmentsRequest } from './Index-workshop-enlistments-request';

@Injectable({ providedIn: 'root' })
export class WorkshopEnlistmentService extends ApiService<
    WorkshopEnlistment,
    IndexWorkshopEnlistmentsRequest,
    any,
    StoreWorkshopEnlistmentRequest
> {
    protected baseUrl = 'workshop-enlistments';
}
