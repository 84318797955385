import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    MoveActionsEnum,
    showMoveFailureAction,
    showMoveSuccessAction,
} from '@action/moves/move.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Move } from '@utility/move/move';
import { MoveService } from '@utility/move/move.service';

@Injectable()
export class MoveEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly moveService: MoveService,
    ) {}

    /**
     * Show move effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showMoveEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MoveActionsEnum.SHOW_MOVE),
            mergeMap((props: { slug: string }) =>
                this.moveService.show(props.slug).pipe(
                    map((response: ResponseResource<Move>) =>
                        showMoveSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showMoveFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
