import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Set } from '@utility/sets/set';

export enum SetActionsEnum {
    SHOW_SET = '[SET] Show set',
    SHOW_SET_SUCCESS = '[SET] Show set success',
    SHOW_SET_FAILURE = '[SET] Show set failure',

    RESET_SET = '[SET] Reset set',
}

export const showSetAction = createAction(
    SetActionsEnum.SHOW_SET,
    props<{ slug: string }>(),
);
export const showSetSuccessAction = createAction(
    SetActionsEnum.SHOW_SET_SUCCESS,
    props<ResponseResource<Set>>(),
);
export const showSetFailureAction = createAction(
    SetActionsEnum.SHOW_SET_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetSetAction = createAction(SetActionsEnum.RESET_SET);
