import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollService {
    public scrollDisabled$: Observable<boolean>;
    public scrollTop$: Observable<number>;

    private readonly scrollDisabledSubject$: BehaviorSubject<boolean>;
    private readonly scrollTopSubject$: BehaviorSubject<number>;

    constructor(@Inject(DOCUMENT) private readonly document: Document) {
        this.scrollDisabledSubject$ = new BehaviorSubject(false);
        this.scrollDisabled$ = this.scrollDisabledSubject$.asObservable();
        this.scrollTopSubject$ = new BehaviorSubject(0);
        this.scrollTop$ = this.scrollTopSubject$.asObservable();
    }

    /**
     * Enable scrolling.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public async enable(): Promise<void> {
        this.scrollDisabledSubject$.next(false);

        const scrollTop = await firstValueFrom(this.scrollTop$);
        this.document.documentElement.scrollTo({ left: 0, top: scrollTop });

        this.document.documentElement.removeAttribute('class');
    }

    /**
     * Disabled scrolling.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public disable(): void {
        this.scrollDisabledSubject$.next(true);
        this.scrollTopSubject$.next(this.document.documentElement.scrollTop);

        this.document.documentElement.setAttribute('class', 'scroll-disabled');
    }
}
