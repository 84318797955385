import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Translations loader factory.
 *
 * @author Niek van der Velde <niek@aimtofeel.com>
 * @version 1.0.0
 */
export const translationsLoaderFactory = (
    httpClient: HttpClient,
): TranslateHttpLoader =>
    new TranslateHttpLoader(httpClient, 'assets/lang/', '/i18next.json');
