import { SessionActionsEnum } from '@action/session/session.actions';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { withLatestFrom } from 'rxjs';
import { SessionState } from '@state/session.state';

@Injectable()
export class SessionEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private readonly router: Router,
    ) {}

    /**
     * Move on session effects.
     *
     * @returns Observable.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public moveOnSessionEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SessionActionsEnum.MOVE_ON_SESSION),
                withLatestFrom(
                    this.store.select((state: AppState) => state.sessionState),
                    this.store.select((state: AppState) => state.router?.state),
                ),
                tap(
                    async ([_, sessionState, routerState]: [
                        never,
                        SessionState,
                        RouterStateSnapshot,
                    ]) => {
                        const newRoute = `/moves/${
                            sessionState.currentMove ?? ''
                        }`;

                        if (
                            !sessionState.currentMove ||
                            routerState.url === newRoute
                        ) {
                            this.router.navigate(['/evaluation']);
                            return;
                        }

                        this.router.navigate([
                            'moves',
                            sessionState.currentMove,
                        ]);
                    },
                ),
            ),
        { dispatch: false },
    );
}
