import {
    ActivityActionsEnum,
    storeActivityFailureAction,
    storeActivitySuccessAction,
} from '@action/activities/activity.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ActivityService } from '@utility/activity/activity.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { StoreActivityRequestProps } from '@utility/activity/store-activity-request';
import { ResponseResource } from '@utility/http/response-resource';
import { Activity } from '@utility/activity/activity';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ActivityEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly activityService: ActivityService,
    ) {}

    /**
     * Create store activity effect.
     *
     * @returns Observable.
     *
     * @author Niek van der velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storeActivityEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActionsEnum.STORE_ACTIVITY),
            mergeMap((props: StoreActivityRequestProps) =>
                this.activityService.store(props.payload).pipe(
                    map((response: ResponseResource<Activity>) =>
                        storeActivitySuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(storeActivityFailureAction(error)),
                    ),
                ),
            ),
        ),
    );
}
