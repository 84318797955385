import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationGuard } from '@shared/guards/authentication.guard';

@Injectable()
export class GuestGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly authenticationGuard: AuthenticationGuard,
    ) {}

    /**
     * Can activate route protected by authentication.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        const result = await this.authenticationGuard.canActivate(route, state);

        return result instanceof UrlTree
            ? true
            : this.router.createUrlTree(['/', 'dashboard']);
    }
}
