import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopsService } from '@utility/workshops/workshops.service';
import {
    loadWorkshopsFailureAction,
    loadWorkshopsSuccessAction,
    WorkshopsActionsEnum,
} from '@action/workshops/workshops.actions';
import { WorkshopsIndexRequestProps } from '@utility/workshops/workshops-index-request';
import { Workshop } from '@utility/workshops/workshop';

@Injectable({ providedIn: 'root' })
export class WorkshopsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopsService: WorkshopsService,
    ) {}

    /**
     * Create workshops effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadMovesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopsActionsEnum.LoadWorkshops),
            mergeMap((data: WorkshopsIndexRequestProps) =>
                this.workshopsService.index(data.payload).pipe(
                    map((response: ResponseResourceCollection<Workshop>) =>
                        loadWorkshopsSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadWorkshopsFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
