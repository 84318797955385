import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { AuthenticationTokenInterceptor } from './authentication-token.interceptor';
import { LanguageHeaderInterceptor } from './language-header.interceptor';
import { ToCamelCaseInterceptor } from './to-camel-case.interceptor';

export const httpInterceptorProvider: Provider[] = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ToCamelCaseInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthenticationTokenInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LanguageHeaderInterceptor,
        multi: true,
    },
];
