import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { ListState } from '@state/list.state';
import { HttpErrorResponse } from '@angular/common/http';
import { getInitialListState } from '@state/list.state';

export const baseLoadListReducer = <T>(state: ListState<T>): ListState<T> => ({
    ...state,
    loading: true,
});

export const baseLoadListSuccessReducer = <T>(
    state: ListState<T>,
    response: ResponseResourceCollection<T>,
): ListState<T> => ({
    ...state,
    data: [...state.data, ...response.data],
    loading: false,
    finished: response.data.length < state.limit,
    page: state.page + 1,
});

export const baseLoadListFailureReducer = <T>(
    state: ListState<T>,
    error: HttpErrorResponse,
): ListState<T> => ({
    ...state,
    loading: false,
    finished: true,
    error,
});

export const baseResetListReducer = <T>(): ListState<T> => ({
    ...getInitialListState<T>(),
});
