import { createReducer, Action, on } from '@ngrx/store';
import {
    baseDeleteItemFailureReducer,
    baseDeleteItemReducer,
    baseDeleteItemSuccessReducer,
    baseStoreItemFailureReducer,
    baseStoreItemReducer,
    baseStoreItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';
import {
    deleteWorkshopDateAction,
    deleteWorkshopDateFailureAction,
    deleteWorkshopDateSuccessAction,
    sendWorkshopDateEmailAction,
    sendWorkshopDateEmailFailureAction,
    sendWorkshopDateEmailSuccessAction,
    storeWorkshopDateAction,
    storeWorkshopDateFailureAction,
    storeWorkshopDateSuccessAction,
} from '@action/workshop-dates/workshop-date.actions';

export const workshopDateReducer = (
    itemState: ItemState<WorkshopDate>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<WorkshopDate>(),

        on(storeWorkshopDateAction, state => baseStoreItemReducer(state)),
        on(storeWorkshopDateSuccessAction, (state, response) =>
            baseStoreItemSuccessReducer<WorkshopDate>(state, response),
        ),
        on(storeWorkshopDateFailureAction, (state, response) =>
            baseStoreItemFailureReducer<WorkshopDate>(state, response.error),
        ),

        on(deleteWorkshopDateAction, state => baseDeleteItemReducer(state)),
        on(deleteWorkshopDateSuccessAction, state =>
            baseDeleteItemSuccessReducer<WorkshopDate>(state),
        ),
        on(deleteWorkshopDateFailureAction, (state, response) =>
            baseDeleteItemFailureReducer<WorkshopDate>(state, response.error),
        ),

        on(sendWorkshopDateEmailAction, state => ({
            ...state,
            loading: true,
        })),
        on(sendWorkshopDateEmailSuccessAction, state => ({
            ...state,
            loading: false,
            isSuccessful: true,
        })),
        on(sendWorkshopDateEmailFailureAction, state => ({
            ...state,
            loading: false,
        })),
    )(itemState, action);
