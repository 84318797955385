import {
    AuthenticationActionsEnum,
    checkAuthenticationFailureAction,
    checkAuthenticationSuccessAction,
    loginFailureAction,
    loginSuccessAction,
    refreshAuthenticationFailureAction,
    refreshAuthenticationSuccessAction,
} from '@action/authentication/authentication.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthenticationService } from '@utility/authentication/authentication.service';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ActionProps } from '@action/action-props';
import { LoginRequestParameters } from '@utility/authentication/login-request-parameters';
import { ResponseResource } from '@utility/http/response-resource';
import { AuthenticationCredentials } from '@utility/authentication/authentication-credentials';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { RefreshAuthenticationRequestParameters } from '@utility/authentication/refresh-request-parameters';
import { User } from '@utility/user/user';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { loadMessagesCountAction } from '@action/messages/messages-count.actions';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable({ providedIn: 'root' })
export class AuthenticationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly authenticationService: AuthenticationService,
        private readonly store: Store<AppState>,
        private readonly matomoTracker: MatomoTracker,
    ) {}

    /**
     * Create login effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loginEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.Login),
            mergeMap((data: ActionProps<LoginRequestParameters>) =>
                this.authenticationService.login(data.payload).pipe(
                    map(
                        (
                            response: ResponseResource<AuthenticationCredentials>,
                        ) => loginSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loginFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create refresh effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public refreshAuthenticationEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.RefreshAuthentication),
            mergeMap(
                (data: ActionProps<RefreshAuthenticationRequestParameters>) =>
                    this.authenticationService.refresh(data.payload).pipe(
                        map(
                            (
                                response: ResponseResource<AuthenticationCredentials>,
                            ) => refreshAuthenticationSuccessAction(response),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(refreshAuthenticationFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );

    /**
     * Check authentication effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public checkAuthenticationEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActionsEnum.CheckAuthentication),
            mergeMap(() =>
                this.authenticationService.check().pipe(
                    map((response: ResponseResource<User>) =>
                        checkAuthenticationSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(checkAuthenticationFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Check authentication success effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public checkAuthenticationSuccessEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionsEnum.CheckAuthenticationSuccess),
                tap((response: ResponseResource<User>) => {
                    this.store.dispatch(loadMessagesCountAction());

                    this.matomoTracker.setUserId(response.data.id.toString());
                }),
            ),
        { dispatch: false },
    );

    /**
     * Create success action effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loginSuccessActionEffects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionsEnum.LoginSuccess),
                tap((response: ResponseResource<AuthenticationCredentials>) => {
                    if (response.data.refreshToken) {
                        localStorage.setItem(
                            'authenticationToken',
                            response.data.authenticationToken,
                        );
                        localStorage.setItem(
                            'refreshToken',
                            response.data.refreshToken,
                        );
                    } else {
                        sessionStorage.setItem(
                            'authenticationToken',
                            response.data.authenticationToken,
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Create logout action effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public logoutActionEffects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthenticationActionsEnum.Logout),
                tap(() => {
                    localStorage.removeItem('authenticationToken');
                    localStorage.removeItem('refreshToken');
                    sessionStorage.removeItem('authenticationToken');
                }),
            ),
        { dispatch: false },
    );
}
