import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopDateService } from '@utility/workshop-date/workshop-date.service';
import {
    indexWorkshopDatesFailureAction,
    indexWorkshopDatesSuccessAction,
    WorkshopDatesActionsEnum,
} from '@action/workshop-dates/workshop-dates.actions';
import { IndexWorkshopDateProps } from '@utility/workshop-date/index-workshop-dates-request';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';

@Injectable({ providedIn: 'root' })
export class WorkshopDatesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopDateService: WorkshopDateService,
    ) {}

    /**
     * Create index workshop dates effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public indexWorkshopDatesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopDatesActionsEnum.IndexWorkshopDates),
            mergeMap((data: IndexWorkshopDateProps) =>
                this.workshopDateService.index(data.payload).pipe(
                    map((response: ResponseResourceCollection<WorkshopDate>) =>
                        indexWorkshopDatesSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(indexWorkshopDatesFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
