import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FooterService } from '@utility/footer/footer.service';
import { MenuService } from '@utility/menu/menu.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthenticated } from '@selector/authentication/authentication.selectors';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
    public visibility$: Observable<boolean>;
    public isAuthenticated$: Observable<boolean>;

    constructor(
        private readonly menuService: MenuService,
        private readonly footerService: FooterService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * On component init lifecycle event
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public ngOnInit(): void {
        this.visibility$ = this.footerService.visibility$;
        this.isAuthenticated$ = this.store.select(selectAuthenticated);
    }

    /**
     * Toggle menu status.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public toggleMenu(): void {
        this.menuService.toggleStatus();
    }
}
