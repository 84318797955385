import {
    resetActivityAction,
    storeActivityAction,
    storeActivityFailureAction,
    storeActivitySuccessAction,
} from '@action/activities/activity.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseStoreItemSuccessReducer,
    baseStoreItemReducer,
    baseStoreItemFailureReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Activity } from '@utility/activity/activity';

export const activityReducer = (
    itemState: ItemState<Activity>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<Activity>(),
        on(storeActivityAction, state => baseStoreItemReducer(state)),
        on(storeActivitySuccessAction, (state, response) =>
            baseStoreItemSuccessReducer<Activity>(state, response),
        ),
        on(storeActivityFailureAction, (state, response) =>
            baseStoreItemFailureReducer<Activity>(state, response.error),
        ),
        on(resetActivityAction, () => baseResetItemReducer<Activity>()),
    )(itemState, action);
