import {
    showCouponAction,
    showCouponFailureAction,
    showCouponSuccessAction,
    resetCouponAction,
} from '@action/coupons/coupon.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Coupon } from '@utility/coupon/coupon';

export const couponReducer = (itemState: ItemState<Coupon>, action: Action) =>
    createReducer(
        getInitialItemState<Coupon>(),
        on(showCouponAction, state => baseShowItemReducer(state)),
        on(showCouponSuccessAction, (state, response) =>
            baseShowItemSuccessReducer<Coupon>(state, response),
        ),
        on(showCouponFailureAction, (state, response) =>
            baseShowItemFailureReducer<Coupon>(state, response.error),
        ),
        on(resetCouponAction, () => baseResetItemReducer<Coupon>()),
    )(itemState, action);
