import { createAction, props } from '@ngrx/store';
import { MovesIndexRequestProps } from '@utility/move/moves-index-request';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { Move } from '@utility/move/move';
import { HttpErrorResponse } from '@angular/common/http';

export enum MovesActionsEnum {
    LoadMoves = '[MOVES] Load moves',
    LoadMovesSuccess = '[MOVES] Load moves success',
    LoadMovesFailure = '[MOVES] Load moves failure',

    ResetMoves = '[MOVES] Reset moves',
}

export const loadMovesAction = createAction(
    MovesActionsEnum.LoadMoves,
    props<MovesIndexRequestProps>(),
);

export const loadMovesSuccessAction = createAction(
    MovesActionsEnum.LoadMovesSuccess,
    props<ResponseResourceCollection<Move>>(),
);

export const loadMovesFailureAction = createAction(
    MovesActionsEnum.LoadMovesFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetMovesAction = createAction(MovesActionsEnum.ResetMoves);
