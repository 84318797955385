import { HttpErrorResponse } from '@angular/common/http';

export type ListState<T> = {
    data: T[];
    loading: boolean;
    finished: boolean;
    page: number;
    limit: number;
    error?: HttpErrorResponse;
};

export const getInitialListState = <T>(): ListState<T> => ({
    data: [],
    loading: true,
    finished: false,
    page: 1,
    limit: 20,
    error: null,
});
