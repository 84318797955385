import { TranslateService } from '@ngx-translate/core';

/**
 * Translation initialize factory.
 *
 * @param translateService
 *
 * @author Niek van der Velde <niek@aimtofeel.com>
 * @version 1.0.0
 */
export const translationInitializeFactory = (
    translateService: TranslateService,
) => () => {
    translateService.setDefaultLang('nl');
};
