import { createAction, props } from '@ngrx/store';
import { StartSessionProps } from '@utility/session/start-session-props';

export enum SessionActionsEnum {
    START_SESSION = '[SESSION] Start session',
    STOP_SESSION = '[SESSION] Stop session',
    RESET_SESSION = '[SESSION] Reset session',
    MOVE_ON_SESSION = '[SESSION] Move on session',
}

export const startSessionAction = createAction(
    SessionActionsEnum.START_SESSION,
    props<StartSessionProps>(),
);

export const stopSessionAction = createAction(SessionActionsEnum.STOP_SESSION);

export const resetSessionAction = createAction(
    SessionActionsEnum.RESET_SESSION,
);

export const moveOnSessionAction = createAction(
    SessionActionsEnum.MOVE_ON_SESSION,
);
