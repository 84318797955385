import {
    loadActivitiesAction,
    loadActivitiesFailureAction,
    loadActivitiesSuccessAction,
    resetActivitiesAction,
} from '@action/activities/activities.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { Activity } from '@utility/activity/activity';

export const activitiesReducer = (
    listState: ListState<Activity>,
    action: Action,
) =>
    createReducer(
        getInitialListState<Activity>(),
        on(loadActivitiesAction, state => baseLoadListReducer(state)),
        on(loadActivitiesSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(loadActivitiesFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetActivitiesAction, () => baseResetListReducer<Activity>()),
    )(listState, action);
