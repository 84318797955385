import { createAction, props } from '@ngrx/store';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { IndexWorkshopEnlistmentsProps } from '@utility/workshop-enlistment/Index-workshop-enlistments-request';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';

export enum WorkshopEnlistmentsActionsEnum {
    LoadWorkshopEnlistments = '[WORKSHOP ENLISTMENTS] Load workshop enlistments',
    LoadWorkshopEnlistmentsSuccess = '[WORKSHOP ENLISTMENTS] Load workshop enlistments success',
    LoadWorkshopEnlistmentsFailure = '[WORKSHOP ENLISTMENTS] Load workshop enlistments failure',

    ResetWorkshopEnlistments = '[WORKSHOP ENLISTMENTS] Reset workshop enlistments',
}

export const loadWorkshopEnlistmentsAction = createAction(
    WorkshopEnlistmentsActionsEnum.LoadWorkshopEnlistments,
    props<IndexWorkshopEnlistmentsProps>(),
);

export const loadWorkshopEnlistmentsSuccessAction = createAction(
    WorkshopEnlistmentsActionsEnum.LoadWorkshopEnlistmentsSuccess,
    props<ResponseResourceCollection<WorkshopEnlistment>>(),
);

export const loadWorkshopEnlistmentsFailureAction = createAction(
    WorkshopEnlistmentsActionsEnum.LoadWorkshopEnlistmentsFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetWorkshopEnlistmentsAction = createAction(
    WorkshopEnlistmentsActionsEnum.ResetWorkshopEnlistments,
);
