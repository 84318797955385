import {
    loadPlaylistsAction,
    loadPlaylistsFailureAction,
    loadPlaylistsSuccessAction,
    resetPlaylistsAction,
} from '@action/playlists/playlists.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { ListState, getInitialListState } from '@state/list.state';
import { Playlist } from '@utility/playlist/playlist';

export const playlistsReducer = (
    listState: ListState<Playlist>,
    action: Action,
) =>
    createReducer(
        getInitialListState<Playlist>(),
        on(loadPlaylistsAction, state => baseLoadListReducer(state)),
        on(loadPlaylistsSuccessAction, (state, response) =>
            baseLoadListSuccessReducer<Playlist>(state, response),
        ),
        on(loadPlaylistsFailureAction, (state, response) =>
            baseLoadListFailureReducer<Playlist>(state, response.error),
        ),
        on(resetPlaylistsAction, () => baseResetListReducer<Playlist>()),
    )(listState, action);
