import {
    storeUserPasswordResetRequestSuccessAction,
    UserPasswordResetRequestActionsEnum,
    storeUserPasswordResetRequestFailureAction,
    updateUserPasswordResetRequestSuccessAction,
    updateUserPasswordResetRequestFailureAction,
} from '@action/user-password-reset-request/user-password-reset-request.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { UserPasswordResetRequest } from '@utility/user-password-reset-request/user-password-reset-request';
import { StoreUserPasswordResetRequestProps } from '@utility/user-password-reset-request/store-user-password-reset-request';
import { UpdateUserPasswordResetRequestProps } from '@utility/user-password-reset-request/update-user-password-reset-request';
import { UserPasswordResetRequestService } from '@utility/user-password-reset-request/user-password-reset-request.service';

@Injectable()
export class UserPasswordResetRequestEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userPasswordResetRequestService: UserPasswordResetRequestService,
    ) {}

    /**
     * Create store user password reset request effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storeUserPasswordResetRequestEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserPasswordResetRequestActionsEnum.STORE_USER_PASSWORD_RESET_REQUEST,
            ),
            mergeMap((props: StoreUserPasswordResetRequestProps) =>
                this.userPasswordResetRequestService.store(props.payload).pipe(
                    map(
                        (
                            response: ResponseResource<UserPasswordResetRequest>,
                        ) =>
                            storeUserPasswordResetRequestSuccessAction(
                                response,
                            ),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            storeUserPasswordResetRequestFailureAction({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create update user password reset request effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updateUserPasswordResetRequestEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserPasswordResetRequestActionsEnum.UPDATE_USER_PASSWORD_RESET_REQUEST,
            ),
            mergeMap((props: UpdateUserPasswordResetRequestProps) =>
                this.userPasswordResetRequestService.update(props.payload).pipe(
                    map(
                        (
                            response: ResponseResource<UserPasswordResetRequest>,
                        ) =>
                            updateUserPasswordResetRequestSuccessAction(
                                response,
                            ),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            updateUserPasswordResetRequestFailureAction({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
