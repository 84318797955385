import { createAction, props } from '@ngrx/store';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpErrorResponse } from '@angular/common/http';
import { StoreUserPasswordResetRequestProps } from '@utility/user-password-reset-request/store-user-password-reset-request';
import { UpdateUserPasswordResetRequestProps } from '@utility/user-password-reset-request/update-user-password-reset-request';
import { UserPasswordResetRequest } from '@utility/user-password-reset-request/user-password-reset-request';

export enum UserPasswordResetRequestActionsEnum {
    STORE_USER_PASSWORD_RESET_REQUEST = '[USER PASSWORD RESET REQUEST] Store user password reset request',
    STORE_USER_PASSWORD_RESET_REQUEST_SUCCESS = '[USER PASSWORD RESET REQUEST] Store user password reset request success',
    STORE_USER_PASSWORD_RESET_REQUEST_FAILURE = '[USER PASSWORD RESET REQUEST] Store user password reset request failure',

    UPDATE_USER_PASSWORD_RESET_REQUEST = '[USER PASSWORD RESET REQUEST] Update user password reset request',
    UPDATE_USER_PASSWORD_RESET_REQUEST_SUCCESS = '[USER PASSWORD RESET REQUEST] Update user password reset request success',
    UPDATE_USER_PASSWORD_RESET_REQUEST_FAILURE = '[USER PASSWORD RESET REQUEST] Update user password reset request failure',

    RESET_USER_PASSWORD_RESET_REQUEST = '[USER PASSWORD RESET REQUEST] Reset user password reset request',
}

export const storeUserPasswordResetRequestAction = createAction(
    UserPasswordResetRequestActionsEnum.STORE_USER_PASSWORD_RESET_REQUEST,
    props<StoreUserPasswordResetRequestProps>(),
);
export const storeUserPasswordResetRequestSuccessAction = createAction(
    UserPasswordResetRequestActionsEnum.STORE_USER_PASSWORD_RESET_REQUEST_SUCCESS,
    props<ResponseResource<UserPasswordResetRequest>>(),
);
export const storeUserPasswordResetRequestFailureAction = createAction(
    UserPasswordResetRequestActionsEnum.STORE_USER_PASSWORD_RESET_REQUEST_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const updateUserPasswordResetRequestAction = createAction(
    UserPasswordResetRequestActionsEnum.UPDATE_USER_PASSWORD_RESET_REQUEST,
    props<UpdateUserPasswordResetRequestProps>(),
);
export const updateUserPasswordResetRequestSuccessAction = createAction(
    UserPasswordResetRequestActionsEnum.UPDATE_USER_PASSWORD_RESET_REQUEST_SUCCESS,
    props<ResponseResource<UserPasswordResetRequest>>(),
);
export const updateUserPasswordResetRequestFailureAction = createAction(
    UserPasswordResetRequestActionsEnum.UPDATE_USER_PASSWORD_RESET_REQUEST_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetUserPasswordResetRequestAction = createAction(
    UserPasswordResetRequestActionsEnum.RESET_USER_PASSWORD_RESET_REQUEST,
);
