import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCatcherResource } from '@utility/http/error-catcher-resource';

export type ItemState<T> = {
    data: T;
    loading: boolean;
    error?: HttpErrorResponse;
    errorResource?: ErrorCatcherResource;
    isSuccessful: boolean;
};

export const getInitialItemState = <T>(): ItemState<T> => ({
    data: null,
    loading: false,
    error: null,
    isSuccessful: false,
});
