<header
    [ngClass]="{
        'header-shadow': !(shadowless$ | async)
    }"
    [ngStyle]="{
        'background-color': color$ | async
    }"
>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="header__container">
                    <button
                        class="back-button"
                        [attr.aria-label]="
                            'shared.header.back_button' | translate
                        "
                        (click)="navigateBack()"
                        *ngIf="
                            (authenticatedUser$ | async) &&
                            !(isDashboard$ | async) &&
                            !(hideMenuButton$ | async)
                        "
                    >
                        <figure>
                            <img
                                src="/assets/images/back-white.svg"
                                width="19px"
                                height="31px"
                            />
                        </figure>
                    </button>

                    <a
                        [routerLink]="[
                            '/' +
                                ((isAuthenticated$ | async) ? 'dashboard' : '')
                        ]"
                        [attr.aria-label]="
                            'shared.header.dashboard' | translate
                        "
                        *ngIf="
                            !(authenticatedUser$ | async) ||
                            (isDashboard$ | async) ||
                            (hideMenuButton$ | async)
                        "
                    >
                        <figure
                            *ngIf="(color$ | async) === ColorEnum.White"
                            class="logo"
                        >
                            <img
                                alt="logo TAT HOME"
                                width="65"
                                height="65"
                                src="/assets/images/logo-color.svg"
                            />
                        </figure>

                        <figure
                            *ngIf="(color$ | async) !== ColorEnum.White"
                            class="logo"
                        >
                            <img
                                alt="logo TAT HOME"
                                width="55"
                                height="59"
                                src="/assets/images/logo-white.svg"
                            />
                        </figure>
                    </a>

                    <div>
                        <p
                            *ngIf="subTitle$ | async"
                            [ngStyle]="{ color: textColor$ | async }"
                        >
                            {{ subTitle$ | async }}
                        </p>

                        <h1
                            *ngIf="title$ | async"
                            [ngStyle]="{ color: textColor$ | async }"
                        >
                            {{ title$ | async }}
                        </h1>
                    </div>

                    <nav>
                        <button
                            *ngIf="
                                (authenticatedUser$ | async) &&
                                !(hideMenuButton$ | async)
                            "
                            (click)="toggleMenu()"
                            class="mobile-menu-button"
                            [ngClass]="{
                                'mobile-menu-button--avium':
                                    (color$ | async) === ColorEnum.White
                            }"
                            [attr.aria-label]="
                                'shared.header.open_menu' | translate
                            "
                        >
                            <i></i>
                            <i></i>
                            <i></i>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>
