<footer class="static-footer" *ngIf="!(isAuthenticated$ | async)">
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="static-footer__list">
                    <li class="static-footer__list-item">
                        <a
                            class="static-footer__link"
                            [routerLink]="['/', 'terms-and-conditions']"
                        >
                            {{
                                'shared.footer.terms_and_conditions_link'
                                    | translate
                            }}
                        </a>
                    </li>
                    <li>
                        <a
                            class="static-footer__link"
                            [routerLink]="['/', 'privacy-statement']"
                        >
                            {{
                                'shared.footer.privacy_statement_link'
                                    | translate
                            }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<footer *ngIf="visibility$ | async" class="fixed-footer">
    <button>
        <a [routerLink]="['/', 'activities']"
            ><figure>
                <img src="/assets/images/activity.svg" width="20" height="21" />
            </figure>
            {{ 'shared.footer.activities' | translate }}
        </a></button
    ><button>
        <a [routerLink]="['/dashboard']"
            ><figure>
                <img
                    src="/assets/images/home-button.svg"
                    width="25"
                    height="25"
                />
            </figure>
            {{ 'shared.footer.home' | translate }}
        </a></button
    ><button>
        <a [routerLink]="['/', 'sets']"
            ><figure>
                <img src="/assets/images/sets.svg" width="25" height="26" />
            </figure>
            {{ 'shared.footer.sets' | translate }}
        </a>
    </button>
</footer>
