import {
    loadMessagesCountAction,
    loadMessagesCountFailureAction,
    loadMessagesCountSuccessAction,
    resetMessagesCountAction,
} from '@action/messages/messages-count.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
    baseResetItemReducer,
} from '@reducer/base/base-item.reducer';
import { getInitialItemState, ItemState } from '@state/item.state';
import { UnreadMessagesCount } from '@utility/message/unread-messages-count';

export const messagesCountReducer = (
    itemState: ItemState<UnreadMessagesCount>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<UnreadMessagesCount>(),
        on(loadMessagesCountAction, state => baseShowItemReducer(state)),
        on(loadMessagesCountSuccessAction, (state, response) =>
            baseShowItemSuccessReducer(state, response),
        ),
        on(loadMessagesCountFailureAction, (state, response) =>
            baseShowItemFailureReducer(state, response.error),
        ),
        on(resetMessagesCountAction, () =>
            baseResetItemReducer<UnreadMessagesCount>(),
        ),
    )(itemState, action);
