import { ActionProps } from '@action/action-props';
import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AuthenticationCredentials } from '@utility/authentication/authentication-credentials';
import { LoginRequestParameters } from '@utility/authentication/login-request-parameters';
import { RefreshAuthenticationRequestParameters } from '@utility/authentication/refresh-request-parameters';
import { User } from '@utility/user/user';
import { ResponseResource } from '@utility/http/response-resource';

export enum AuthenticationActionsEnum {
    Login = '[AUTHENTICATION] Login',
    LoginSuccess = '[AUTHENTICATION] Login success',
    LoginFailure = '[AUTHENTICATION] Login failure',

    RefreshAuthentication = '[AUTHENTICATION] Refresh authentication',
    RefreshAuthenticationSuccess = '[AUTHENTICATION] Refresh authentication success',
    RefreshAuthenticationFailure = '[AUTHENTICATION] Refresh authentication failure',

    CheckAuthentication = '[AUTHENTICATION] Check authentication',
    CheckAuthenticationSuccess = '[AUTHENTICATION] Check authentication success',
    CheckAuthenticationFailure = '[AUTHENTICATION] Check authentication failure',

    Logout = '[AUTHENTICATION] Logout',

    SetAuthenticatedUser = '[AUTHENTICATION] Set authenticated user',
    UpdateAuthenticatedUserProp = '[AUTHENTICATION] Update authenticated user prop',
}

export const loginAction = createAction(
    AuthenticationActionsEnum.Login,
    props<ActionProps<LoginRequestParameters>>(),
);
export const loginSuccessAction = createAction(
    AuthenticationActionsEnum.LoginSuccess,
    props<ResponseResource<AuthenticationCredentials>>(),
);
export const loginFailureAction = createAction(
    AuthenticationActionsEnum.LoginFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const refreshAuthenticationAction = createAction(
    AuthenticationActionsEnum.RefreshAuthentication,
    props<ActionProps<RefreshAuthenticationRequestParameters>>(),
);
export const refreshAuthenticationSuccessAction = createAction(
    AuthenticationActionsEnum.RefreshAuthenticationSuccess,
    props<ResponseResource<AuthenticationCredentials>>(),
);
export const refreshAuthenticationFailureAction = createAction(
    AuthenticationActionsEnum.RefreshAuthenticationFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const checkAuthenticationAction = createAction(
    AuthenticationActionsEnum.CheckAuthentication,
);
export const checkAuthenticationSuccessAction = createAction(
    AuthenticationActionsEnum.CheckAuthenticationSuccess,
    props<ResponseResource<User>>(),
);
export const checkAuthenticationFailureAction = createAction(
    AuthenticationActionsEnum.CheckAuthenticationFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const logoutAction = createAction(AuthenticationActionsEnum.Logout);

export const setAuthenticatedUserAction = createAction(
    AuthenticationActionsEnum.SetAuthenticatedUser,
    props<{ user: User }>(),
);

export const updateAuthenticatedUserPropAction = createAction(
    AuthenticationActionsEnum.UpdateAuthenticatedUserProp,
    props<{ key: string; value?: string }>(),
);
