import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { SetsService } from '@utility/sets/sets.service';
import {
    SetActionsEnum,
    showSetFailureAction,
    showSetSuccessAction,
} from '@action/sets/set.actions';
import { Set } from '@utility/sets/set';

@Injectable()
export class SetEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly setService: SetsService,
    ) {}

    /**
     * Show set effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showSetEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetActionsEnum.SHOW_SET),
            mergeMap((props: { slug: string }) =>
                this.setService.show(props.slug).pipe(
                    map((response: ResponseResource<Set>) =>
                        showSetSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showSetFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
