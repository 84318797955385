import {
    indexWorkshopDatesAction,
    indexWorkshopDatesFailureAction,
    indexWorkshopDatesSuccessAction,
    insertWorkshopDateAction,
    removeWorkshopDateAction,
    resetWorkshopDatesAction,
} from '@action/workshop-dates/workshop-dates.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';

export const workshopDatesReducer = (
    listState: ListState<WorkshopDate>,
    action: Action,
) =>
    createReducer(
        getInitialListState<WorkshopDate>(),
        on(indexWorkshopDatesAction, state => baseLoadListReducer(state)),
        on(indexWorkshopDatesSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(indexWorkshopDatesFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetWorkshopDatesAction, () =>
            baseResetListReducer<WorkshopDate>(),
        ),
        on(insertWorkshopDateAction, (state, payload) => ({
            ...state,
            data: [
                ...state.data,
                { ...payload.payload, currentEnlistments: 0 },
            ],
        })),
        on(removeWorkshopDateAction, (state, payload) => ({
            ...state,
            data: state.data.filter(
                workshopDate => workshopDate.id !== payload.workshopDateId,
            ),
        })),
    )(listState, action);
