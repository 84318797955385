import { AppState } from '@state/app.state';

export const selectAuthenticatedUser = (state: AppState) =>
    state.authenticationState.authenticatedUser;

export const selectAuthenticated = (state: AppState) =>
    state.authenticationState.authenticated;

export const selectAuthenticationCredentials = (state: AppState) =>
    state.authenticationState.authenticationCredentials;

export const selectLoginError = (state: AppState) =>
    state.authenticationState.loginError;

export const selectLoginPending = (state: AppState) =>
    state.authenticationState.loginPending;
