import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { Move } from '@utility/move/move';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SetsService } from '@utility/sets/sets.service';
import {
    loadSetsFailureAction,
    loadSetsSuccessAction,
    SetsActionsEnum,
} from '@action/sets/sets.actions';
import { SetsIndexRequestProps } from '@utility/sets/sets-index-request';
import { Set } from '@utility/sets/set';

@Injectable({ providedIn: 'root' })
export class SetsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly setsService: SetsService,
    ) {}

    /**
     * Create sets effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadMovesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetsActionsEnum.LoadSets),
            mergeMap((data: SetsIndexRequestProps) =>
                this.setsService.index(data.payload).pipe(
                    map((response: ResponseResourceCollection<Set>) =>
                        loadSetsSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadSetsFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
