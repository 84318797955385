import { Injectable } from '@angular/core';
import { ApiService } from '@utility/http/api.service';
import { Observable } from 'rxjs';
import { UserPayment } from './user-payment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpService } from '@utility/http/http.service';
import { environment } from 'src/environments/environment';
import { IndexUserPaymentsRequest } from './index-user-payments-request';
import { Params } from '@angular/router';
import { UpdateUserPaymentRequest } from './update-user-payment-request';

@Injectable({ providedIn: 'root' })
export class UserPaymentService extends ApiService<
    UserPayment,
    IndexUserPaymentsRequest,
    Params,
    Params,
    UpdateUserPaymentRequest
> {
    protected baseUrl = 'user-payments';

    constructor(
        protected readonly httpService: HttpService,
        private readonly httpClient: HttpClient,
    ) {
        super(httpService);
    }

    public downloadAsPdf(userPaymentId: number): Observable<Blob> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');

        return this.httpClient.get(
            `${environment.apiBaseUrl}${this.baseUrl}/${userPaymentId}/download-as-pdf`,
            {
                headers,
                responseType: 'blob',
            },
        );
    }
}
