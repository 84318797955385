import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
    /**
     * Add authentication token to request interceptor.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const sessionToken = sessionStorage.getItem('authenticationToken');
        const localStorageToken = localStorage.getItem('authenticationToken');

        if (localStorageToken || sessionToken) {
            return next.handle(
                req.clone({
                    headers: req.headers.set(
                        'Authorization',
                        `${localStorageToken ?? sessionToken}`,
                    ),
                }),
            );
        }

        return next.handle(req);
    }
}
