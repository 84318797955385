import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    MessageActionsEnum,
    showMessageFailureAction,
    showMessageSuccessAction,
} from '@action/messages/message.actions';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { MessageService } from '@utility/message/message.service';
import { Message } from '@utility/message/message';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { loadMessagesCountAction } from '@action/messages/messages-count.actions';

@Injectable()
export class MessageEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly messageService: MessageService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Show message effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showMessageEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessageActionsEnum.SHOW_MESSAGE),
            mergeMap((props: { id: number }) =>
                this.messageService.show(props.id).pipe(
                    map((response: ResponseResource<Message>) =>
                        showMessageSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showMessageFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Show message success effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showMessageSuccessEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MessageActionsEnum.SHOW_MESSAGE_SUCCESS),
                tap(() => {
                    this.store.dispatch(loadMessagesCountAction());
                }),
            ),
        { dispatch: false },
    );
}
