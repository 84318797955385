import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopsService } from '@utility/workshops/workshops.service';
import { Workshop } from '@utility/workshops/workshop';
import {
    showWorkshopFailureAction,
    showWorkshopSuccessAction,
    storeWorkshopFailureAction,
    storeWorkshopSuccessAction,
    updateWorkshopFailureAction,
    updateWorkshopSuccessAction,
    WorkshopActionsEnum,
} from '@action/workshops/workshop.actions';
import { ResponseResource } from '@utility/http/response-resource';
import { StoreWorkshopProps } from '@utility/workshops/store-workshop-request';
import { UpdateWorkshopProps } from '@utility/workshops/update-workshop-request';

@Injectable({ providedIn: 'root' })
export class WorkshopEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopsService: WorkshopsService,
    ) {}

    /**
     * Create show workshop effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showWorkshopEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopActionsEnum.SHOW_WORKSHOP),
            mergeMap((props: { slug: string }) =>
                this.workshopsService.show(props.slug).pipe(
                    map((response: ResponseResource<Workshop>) =>
                        showWorkshopSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showWorkshopFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create store workshop effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storeWorkshopEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopActionsEnum.STORE_WORKSHOP),
            mergeMap((props: StoreWorkshopProps) =>
                this.workshopsService.store(props.payload).pipe(
                    map((response: ResponseResource<Workshop>) =>
                        storeWorkshopSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(storeWorkshopFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create update workshop effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updateWorkshopEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopActionsEnum.UPDATE_WORKSHOP),
            mergeMap((props: UpdateWorkshopProps) =>
                this.workshopsService
                    .update(props.payload, props.workshopId)
                    .pipe(
                        map((response: ResponseResource<Workshop>) =>
                            updateWorkshopSuccessAction(response),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(updateWorkshopFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );
}
