import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from '@utility/message/message.service';
import {
    loadMessagesFailureAction,
    loadMessagesSuccessAction,
    MessagesActionsEnum,
} from '@action/messages/messages.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '@utility/message/message';
import { MessagesIndexRequestProps } from '@utility/message/messages-index-request';

@Injectable()
export class MessagesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly messageService: MessageService,
    ) {}

    /**
     * Create messages effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadMessagesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActionsEnum.LOAD_MESSAGES),
            mergeMap((data: MessagesIndexRequestProps) =>
                this.messageService.index(data.payload).pipe(
                    map((response: ResponseResourceCollection<Message>) =>
                        loadMessagesSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadMessagesFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
