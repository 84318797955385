import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UpdateService } from '@utility/update/update.service';
import { Observable } from 'rxjs';
import { ConfirmationService } from '@utility/confirmation/confirmation.service';
import { ScrollService } from './core/utilities/scroll/scroll.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    public updateAvailable$: Observable<boolean>;
    public confirmationModalVisible$: Observable<boolean>;

    constructor(
        private readonly updateService: UpdateService,
        private readonly confirmationService: ConfirmationService,
        private readonly scrollService: ScrollService,
    ) {}

    /**
     * On component init lifecycle event.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public ngOnInit(): void {
        this.updateAvailable$ = this.updateService.updateAvailable$;
        this.confirmationModalVisible$ =
            this.confirmationService.confirmationVisible$;
    }
}
