import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ScrollService } from '@utility/scroll/scroll.service';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
    public confirmationVisible$: Observable<boolean>;
    public confirmations$: Observable<string>;
    public cancellations$: Observable<string>;
    public question$: Observable<string>;
    public confirmText$: Observable<string | null>;

    private activeKey: string;

    private readonly confirmationVisibleSubject$: BehaviorSubject<boolean>;
    private readonly confirmationsSubject$: BehaviorSubject<string>;
    private readonly cancellationsSubject$: BehaviorSubject<string>;
    private readonly questionSubject$: BehaviorSubject<string>;
    private readonly confirmTextSubject$: BehaviorSubject<string | null>;

    constructor(private readonly scrollService: ScrollService) {
        this.confirmationVisibleSubject$ = new BehaviorSubject(false);
        this.confirmationVisible$ =
            this.confirmationVisibleSubject$.asObservable();
        this.confirmationsSubject$ = new BehaviorSubject('');
        this.confirmations$ = this.confirmationsSubject$.asObservable();
        this.cancellationsSubject$ = new BehaviorSubject('');
        this.cancellations$ = this.cancellationsSubject$.asObservable();
        this.questionSubject$ = new BehaviorSubject('');
        this.question$ = this.questionSubject$.asObservable();
        this.confirmTextSubject$ = new BehaviorSubject(null);
        this.confirmText$ = this.confirmTextSubject$.asObservable();
    }

    /**
     * On confirmation.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public onConfirm(): void {
        this.confirmationsSubject$.next(this.activeKey);

        this.confirmationVisibleSubject$.next(false);

        this.scrollService.enable();
    }

    /**
     * On cancel.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public onCancel(): void {
        this.cancellationsSubject$.next(this.activeKey);

        this.confirmationVisibleSubject$.next(false);

        this.scrollService.enable();
    }

    /**
     * Ask for confirmation.
     *
     * @param key
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public ask(
        key: string,
        question: string,
        confirmText: string | null = null,
    ): void {
        this.confirmationVisibleSubject$.next(true);

        this.activeKey = key;
        this.questionSubject$.next(question);
        this.confirmTextSubject$.next(confirmText);

        this.scrollService.disable();
    }

    /**
     * Reset user response.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public reset(): void {
        this.confirmationsSubject$.next(null);
        this.cancellationsSubject$.next(null);
    }
}
