import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import snakecaseKeys from 'snakecase-keys';

@Injectable({ providedIn: 'root' })
export class HttpService {
    private requestHeaders: HttpHeaders;

    constructor(private readonly httpClient: HttpClient) {}

    /**
     * Perform get request.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public get<T>(url: string, queryParams?: Params): Observable<T> {
        return this.httpClient.get<T>(`${environment.apiBaseUrl}${url}`, {
            params: queryParams,
        });
    }

    /**
     *  Perform post request.
     *
     * @author Niek van der Velde <niek@velde.xyz>
     * @version 1.0.0
     */
    public post<T, Payload>(url: string, payload: Payload): Observable<T> {
        return this.httpClient.post<T>(
            environment.apiBaseUrl + url,
            snakecaseKeys(payload as any),
        );
    }

    /**
     *  Perform put request.
     *
     * @author Niek van der Velde <niek@velde.xyz>
     * @version 1.0.0
     */
    public put<T, Payload>(url: string, payload: Payload): Observable<T> {
        return this.httpClient.put<T>(
            environment.apiBaseUrl + url,
            snakecaseKeys(payload as any),
        );
    }

    /**
     * Perform delete request.
     *
     * @author Niek van der Velde <niek@velde.xyz>
     * @version 1.0.0
     */
    public delete<T>(url: string): Observable<T> {
        return this.httpClient.delete<T>(environment.apiBaseUrl + url);
    }
}
