import {
    resetQuoteAction,
    showQuoteAction,
    showQuoteFailureAction,
    showQuoteSuccessAction,
} from '@action/quotes/quote.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseShowItemReducer,
    baseShowItemSuccessReducer,
    baseShowItemFailureReducer,
    baseResetItemReducer,
} from '@reducer/base/base-item.reducer';
import { getInitialItemState, ItemState } from '@state/item.state';
import { Quote } from '@utility/quotes/quote';

export const quoteReducer = (itemState: ItemState<Quote>, action: Action) =>
    createReducer(
        getInitialItemState<Quote>(),
        on(showQuoteAction, state => baseShowItemReducer(state)),
        on(showQuoteSuccessAction, (state, response) =>
            baseShowItemSuccessReducer(state, response),
        ),
        on(showQuoteFailureAction, (state, response) =>
            baseShowItemFailureReducer(state, response.error),
        ),
        on(resetQuoteAction, () => baseResetItemReducer<Quote>()),
    )(itemState, action);
