import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Workshop } from '@utility/workshops/workshop';
import { StoreWorkshopProps } from '@utility/workshops/store-workshop-request';
import { UpdateWorkshopProps } from '@utility/workshops/update-workshop-request';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';

export enum WorkshopActionsEnum {
    SHOW_WORKSHOP = '[WORKSHOP] Show workshop',
    SHOW_WORKSHOP_SUCCESS = '[WORKSHOP] Show workshop success',
    SHOW_WORKSHOP_FAILURE = '[WORKSHOP] Show workshop failure',

    STORE_WORKSHOP = '[WORKSHOP] Store workshop',
    STORE_WORKSHOP_SUCCESS = '[WORKSHOP] Store workshop success',
    STORE_WORKSHOP_FAILURE = '[WORKSHOP] Store workshop failure',

    UPDATE_WORKSHOP = '[WORKSHOP] Update workshop',
    UPDATE_WORKSHOP_SUCCESS = '[WORKSHOP] Update workshop success',
    UPDATE_WORKSHOP_FAILURE = '[WORKSHOP] Update workshop failure',

    RESET_WORKSHOP = '[WORKSHOP] Reset workshop',

    INCREMENT_ENLISTMENT_COUNT_WORKSHOP = '[WORKSHOP] Increment enlistment count workshop',
}

export const showWorkshopAction = createAction(
    WorkshopActionsEnum.SHOW_WORKSHOP,
    props<{ slug: string }>(),
);
export const showWorkshopSuccessAction = createAction(
    WorkshopActionsEnum.SHOW_WORKSHOP_SUCCESS,
    props<ResponseResource<Workshop>>(),
);
export const showWorkshopFailureAction = createAction(
    WorkshopActionsEnum.SHOW_WORKSHOP_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const storeWorkshopAction = createAction(
    WorkshopActionsEnum.STORE_WORKSHOP,
    props<StoreWorkshopProps>(),
);
export const storeWorkshopSuccessAction = createAction(
    WorkshopActionsEnum.STORE_WORKSHOP_SUCCESS,
    props<ResponseResource<Workshop>>(),
);
export const storeWorkshopFailureAction = createAction(
    WorkshopActionsEnum.STORE_WORKSHOP_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const updateWorkshopAction = createAction(
    WorkshopActionsEnum.UPDATE_WORKSHOP,
    props<UpdateWorkshopProps>(),
);
export const updateWorkshopSuccessAction = createAction(
    WorkshopActionsEnum.UPDATE_WORKSHOP_SUCCESS,
    props<ResponseResource<Workshop>>(),
);
export const updateWorkshopFailureAction = createAction(
    WorkshopActionsEnum.UPDATE_WORKSHOP_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const incrementEnlistmentCountWorkshopAction = createAction(
    WorkshopActionsEnum.INCREMENT_ENLISTMENT_COUNT_WORKSHOP,
    props<{
        payload: {
            workshopDateId: number;
            userId: number;
            workshopEnlistment: WorkshopEnlistment;
        };
    }>(),
);

export const resetWorkshopAction = createAction(
    WorkshopActionsEnum.RESET_WORKSHOP,
);
