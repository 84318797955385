import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Coupon } from '@utility/coupon/coupon';

export enum CouponActionsEnum {
    SHOW_COUPON = '[COUPON] Show coupon',
    SHOW_COUPON_SUCCESS = '[COUPON] Show coupon success',
    SHOW_COUPON_FAILURE = '[COUPON] Show coupon failure',

    RESET_COUPON = '[COUPON] Reset coupon',
}

export const showCouponAction = createAction(
    CouponActionsEnum.SHOW_COUPON,
    props<{ code: string }>(),
);
export const showCouponSuccessAction = createAction(
    CouponActionsEnum.SHOW_COUPON_SUCCESS,
    props<ResponseResource<Coupon>>(),
);
export const showCouponFailureAction = createAction(
    CouponActionsEnum.SHOW_COUPON_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetCouponAction = createAction(CouponActionsEnum.RESET_COUPON);
