<app-menu></app-menu>

<app-header></app-header>

<main>
    <router-outlet></router-outlet>
</main>

<app-footer></app-footer>

<app-update-modal *ngIf="updateAvailable$ | async"></app-update-modal>

<app-confirmation-modal
    *ngIf="confirmationModalVisible$ | async"
></app-confirmation-modal>
