import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Set } from '@utility/sets/set';
import {
    showSetAction,
    showSetFailureAction,
    showSetSuccessAction,
    resetSetAction,
} from '@action/sets/set.actions';

export const setReducer = (itemState: ItemState<Set>, action: Action) =>
    createReducer(
        getInitialItemState<Set>(),
        on(showSetAction, state => baseShowItemReducer(state)),
        on(showSetSuccessAction, (state, response) =>
            baseShowItemSuccessReducer<Set>(state, response),
        ),
        on(showSetFailureAction, (state, response) =>
            baseShowItemFailureReducer<Set>(state, response.error),
        ),
        on(resetSetAction, () => baseResetItemReducer<Set>()),
    )(itemState, action);
