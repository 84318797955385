import { createAction, props } from '@ngrx/store';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { SetsIndexRequestProps } from '@utility/sets/sets-index-request';
import { Set } from '@utility/sets/set';

export enum SetsActionsEnum {
    LoadSets = '[SETS] Load sets',
    LoadSetsSuccess = '[SETS] Load sets success',
    LoadSetsFailure = '[SETS] Load sets failure',

    ResetSets = '[SETS] Reset sets',
}

export const loadSetsAction = createAction(
    SetsActionsEnum.LoadSets,
    props<SetsIndexRequestProps>(),
);

export const loadSetsSuccessAction = createAction(
    SetsActionsEnum.LoadSetsSuccess,
    props<ResponseResourceCollection<Set>>(),
);

export const loadSetsFailureAction = createAction(
    SetsActionsEnum.LoadSetsFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetSetsAction = createAction(SetsActionsEnum.ResetSets);
