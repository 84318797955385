import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
    baseStoreItemFailureReducer,
    baseStoreItemReducer,
    baseStoreItemSuccessReducer,
    baseUpdateItemFailureReducer,
    baseUpdateItemReducer,
    baseUpdateItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Workshop } from '@utility/workshops/workshop';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';
import {
    showWorkshopAction,
    showWorkshopFailureAction,
    showWorkshopSuccessAction,
    resetWorkshopAction,
    incrementEnlistmentCountWorkshopAction,
    storeWorkshopAction,
    storeWorkshopSuccessAction,
    storeWorkshopFailureAction,
    updateWorkshopAction,
    updateWorkshopSuccessAction,
    updateWorkshopFailureAction,
} from '@action/workshops/workshop.actions';

export const workshopReducer = (
    itemState: ItemState<Workshop>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<Workshop>(),

        on(showWorkshopAction, state => baseShowItemReducer(state)),
        on(showWorkshopSuccessAction, (state, response) =>
            baseShowItemSuccessReducer<Workshop>(state, response),
        ),
        on(showWorkshopFailureAction, (state, response) =>
            baseShowItemFailureReducer<Workshop>(state, response.error),
        ),

        on(storeWorkshopAction, state => baseStoreItemReducer(state)),
        on(storeWorkshopSuccessAction, (state, response) =>
            baseStoreItemSuccessReducer<Workshop>(state, response),
        ),
        on(storeWorkshopFailureAction, (state, response) =>
            baseStoreItemFailureReducer<Workshop>(state, response.error),
        ),

        on(updateWorkshopAction, state => baseUpdateItemReducer(state)),
        on(updateWorkshopSuccessAction, (state, response) =>
            baseUpdateItemSuccessReducer<Workshop>(state, response),
        ),
        on(updateWorkshopFailureAction, (state, response) =>
            baseUpdateItemFailureReducer<Workshop>(state, response.error),
        ),

        on(incrementEnlistmentCountWorkshopAction, (state, payload) => ({
            ...state,
            data: {
                ...state.data,
                workshopDates: state.data.workshopDates.map(
                    (workshopDate: WorkshopDate): WorkshopDate => ({
                        ...workshopDate,
                        currentEnlistments:
                            payload.payload.workshopDateId === workshopDate.id
                                ? workshopDate.currentEnlistments + 1
                                : workshopDate.currentEnlistments,
                        workshopEnlistment: payload.payload.workshopEnlistment,
                    }),
                ),
            },
        })),

        on(resetWorkshopAction, () => baseResetItemReducer<Workshop>()),
    )(itemState, action);
