import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlaylistService } from '@utility/playlist/playlist.service';
import {
    PlaylistActionsEnum,
    showPlaylistFailureAction,
    showPlaylistSuccessAction,
    storePlaylistFailureAction,
    storePlaylistSuccessAction,
    updatePlaylistFailureAction,
    updatePlaylistSuccessAction,
    deletePlaylistFailureAction,
    deletePlaylistSuccessAction,
} from '@action/playlists/playlist.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ResponseResource } from '@utility/http/response-resource';
import { Playlist } from '@utility/playlist/playlist';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { StorePlaylistProps } from '@utility/playlist/store-playlist-request';
import { UpdatePLaylistProps } from '@utility/playlist/update-playlist-request';

@Injectable()
export class PlaylistEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly playlistService: PlaylistService,
    ) {}

    /**
     * Show playlist effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showPlaylistEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlaylistActionsEnum.SHOW_PLAYLIST),
            mergeMap((props: { id: number }) =>
                this.playlistService.show(props.id).pipe(
                    map((response: ResponseResource<Playlist>) =>
                        showPlaylistSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showPlaylistFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Store playlist effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storePlaylistEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlaylistActionsEnum.STORE_PLAYLIST),
            mergeMap((props: StorePlaylistProps) =>
                this.playlistService.store(props.payload).pipe(
                    map((response: ResponseResource<Playlist>) =>
                        storePlaylistSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(storePlaylistFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Show playlist effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updatePlaylistEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlaylistActionsEnum.UPDATE_PLAYLIST),
            mergeMap((props: UpdatePLaylistProps) =>
                this.playlistService.update(props.payload, props.id).pipe(
                    map((response: ResponseResource<Playlist>) =>
                        updatePlaylistSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(updatePlaylistFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Delete playlist effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public deletePlaylistEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlaylistActionsEnum.DELETE_PLAYLIST),
            mergeMap((props: { id: number }) =>
                this.playlistService.delete(props.id).pipe(
                    map(() => deletePlaylistSuccessAction()),
                    catchError((error: HttpErrorResponse) =>
                        of(deletePlaylistFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
