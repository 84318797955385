import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuStatus } from './menu-status.enum';
import { ScrollService } from '@utility/scroll/scroll.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
    private readonly menuStatusSubject$: BehaviorSubject<MenuStatus>;
    public menuStatus$: Observable<MenuStatus>;

    constructor(private readonly scrollService: ScrollService) {
        this.menuStatusSubject$ = new BehaviorSubject(MenuStatus.Closed);
        this.menuStatus$ = this.menuStatusSubject$.asObservable();
    }

    /**
     * Change status of menu.
     *
     * @param newMenuStatus
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public changeStatus(newMenuStatus: MenuStatus): void {
        this.menuStatusSubject$.next(newMenuStatus);

        if (newMenuStatus === MenuStatus.Open) {
            this.scrollService.disable();
        } else {
            this.scrollService.enable();
        }
    }

    /**
     * Toggle menu status.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public toggleStatus(): void {
        const newStatus =
            this.menuStatusSubject$.value === MenuStatus.Closed
                ? MenuStatus.Open
                : MenuStatus.Closed;

        this.menuStatusSubject$.next(newStatus);

        if (newStatus === MenuStatus.Open) {
            this.scrollService.disable();
        } else {
            this.scrollService.enable();
        }
    }
}
