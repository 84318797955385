import {
    indexUserPaymentsAction,
    indexUserPaymentsFailureAction,
    indexUserPaymentsSuccessAction,
    resetUserPaymentsAction,
} from '@action/user-payments/user-payments.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { UserPayment } from '@utility/user-payment/user-payment';

export const userPaymentsReducer = (
    listState: ListState<UserPayment>,
    action: Action,
) =>
    createReducer(
        getInitialListState<UserPayment>(),
        on(indexUserPaymentsAction, state => baseLoadListReducer(state)),
        on(indexUserPaymentsSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(indexUserPaymentsFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetUserPaymentsAction, () => baseResetListReducer<UserPayment>()),
    )(listState, action);
