import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { CouponService } from '@utility/coupon/coupon.service';
import {
    CouponActionsEnum,
    showCouponFailureAction,
    showCouponSuccessAction,
} from '@action/coupons/coupon.actions';
import { Coupon } from '@utility/coupon/coupon';

@Injectable()
export class CouponEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly couponService: CouponService,
    ) {}

    /**
     * Show coupon effects.
     *
     * @returns Observable of effect.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showMoveEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CouponActionsEnum.SHOW_COUPON),
            mergeMap((props: { code: string }) =>
                this.couponService.show(props.code).pipe(
                    map((response: ResponseResource<Coupon>) =>
                        showCouponSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showCouponFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
