import {
    loadMessagesAction,
    loadMessagesFailureAction,
    loadMessagesSuccessAction,
    resetMessagesAction,
} from '@action/messages/messages.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { Message } from '@utility/message/message';

export const messagesReducer = (
    listState: ListState<Message>,
    action: Action,
) =>
    createReducer(
        getInitialListState<Message>(),
        on(loadMessagesAction, state => baseLoadListReducer(state)),
        on(loadMessagesSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(loadMessagesFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetMessagesAction, () => baseResetListReducer<Message>()),
    )(listState, action);
