import {
    storeUserFailureAction,
    updateUserFailureAction,
    updateUserSuccessAction,
    cancelSubscriptionSuccessAction,
    cancelSubscriptionFailureAction,
    UserActionsEnum,
    startSubscriptionSuccessAction,
    startSubscriptionFailureAction,
    activateUserSuccessAction,
    activateUserFailureAction,
    updateUserBillingSuccessAction,
    updateUserBillingFailureAction,
} from '@action/users/user.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '@utility/user/user.service';
import { mergeMap, map, catchError, tap, finalize } from 'rxjs/operators';
import { StoreUserRequestProps } from '@utility/user/store-user-request';
import { ResponseResource } from '@utility/http/response-resource';
import { User } from '@utility/user/user';
import { storeUserSuccessAction } from '@action/users/user.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
    setAuthenticatedUserAction,
    updateAuthenticatedUserPropAction,
    loginSuccessAction,
} from '@action/authentication/authentication.actions';
import { SubscriptionService } from '@utility/subscription/subscription.service';
import { SubscriptionPayment } from '@utility/subscription/subscription-payment';
import { ActivateUserProps } from '@utility/user/activate-user-request';
import { UpdateBillingRequestProps } from '@utility/user/update-billing-request';

@Injectable()
export class UserEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userService: UserService,
        private readonly store: Store<AppState>,
        private readonly subscriptionService: SubscriptionService,
    ) {}

    /**
     * Create store user effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storeUserEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionsEnum.STORE_USER),
            mergeMap((props: StoreUserRequestProps) =>
                this.userService.store(props.payload).pipe(
                    map((response: ResponseResource<User>) => {
                        this.store.dispatch(
                            loginSuccessAction({
                                data: response.data.authentication,
                            }),
                        );

                        return storeUserSuccessAction(response);
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(storeUserFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create update user effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updateUserEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionsEnum.UPDATE_USER),
            mergeMap((props: StoreUserRequestProps) =>
                this.userService.update(props.payload).pipe(
                    map((response: ResponseResource<User>) => {
                        this.store.dispatch(
                            setAuthenticatedUserAction({ user: response.data }),
                        );

                        return updateUserSuccessAction(response);
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(updateUserFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create cancel subscription user effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public cancelSubscriptionEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActionsEnum.CANCEL_SUBSCRIPTION),
                mergeMap(() =>
                    this.subscriptionService.delete().pipe(
                        finalize(() => {
                            this.store.dispatch(
                                updateAuthenticatedUserPropAction({
                                    key: 'mollieSubscriptionId',
                                    value: null,
                                }),
                            );

                            this.store.dispatch(
                                cancelSubscriptionSuccessAction(),
                            );
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(cancelSubscriptionFailureAction({ error })),
                        ),
                    ),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Start subscription effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public startSubscriptionEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionsEnum.START_SUBSCRIPTION),
            mergeMap((props: { payload: { couponCode?: string } }) =>
                this.subscriptionService.store(props.payload).pipe(
                    map((response: ResponseResource<SubscriptionPayment>) =>
                        startSubscriptionSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(startSubscriptionFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Activate user effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public activateUserEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionsEnum.ACTIVATE_USER),
            mergeMap((props: ActivateUserProps) =>
                this.userService.activate(props.payload).pipe(
                    map((response: ResponseResource<User>) =>
                        activateUserSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(activateUserFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Update user billing effects.
     *
     * @returns Observable of effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updateUserBillingEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActionsEnum.UPDATE_USER_BILLING),
            mergeMap((props: UpdateBillingRequestProps) =>
                this.userService.updateBilling(props.payload).pipe(
                    map((response: ResponseResource<User>) => {
                        this.store.dispatch(
                            setAuthenticatedUserAction({ user: response.data }),
                        );

                        return updateUserBillingSuccessAction(response);
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(updateUserBillingFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
