import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { StoreWorkshopEnlistmentProps } from '@utility/workshop-enlistment/store-workshop-enlistment-request';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';

export enum WorkshopEnlistmentActionsEnum {
    STORE_WORKSHOP_ENLISTMENT = '[WORKSHOP ENLISTMENT] Store workshop enlistment',
    STORE_WORKSHOP_ENLISTMENT_SUCCESS = '[WORKSHOP ENLISTMENT] Store workshop enlistment success',
    STORE_WORKSHOP_ENLISTMENT_FAILURE = '[WORKSHOP ENLISTMENT] Store workshop enlistment failure',

    RESET_WORKSHOP_ENLISTMENT = '[WORKSHOP ENLISTMENT] Reset workshop enlistment',
}

export const storeWorkshopEnlistmentAction = createAction(
    WorkshopEnlistmentActionsEnum.STORE_WORKSHOP_ENLISTMENT,
    props<StoreWorkshopEnlistmentProps>(),
);
export const storeWorkshopEnlistmentSuccessAction = createAction(
    WorkshopEnlistmentActionsEnum.STORE_WORKSHOP_ENLISTMENT_SUCCESS,
    props<ResponseResource<WorkshopEnlistment>>(),
);
export const storeWorkshopEnlistmentFailureAction = createAction(
    WorkshopEnlistmentActionsEnum.STORE_WORKSHOP_ENLISTMENT_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetWorkshopEnlistmentAction = createAction(
    WorkshopEnlistmentActionsEnum.RESET_WORKSHOP_ENLISTMENT,
);
