import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Message } from '@utility/message/message';

export enum MessageActionsEnum {
    SHOW_MESSAGE = '[MESSAGE] Show message',
    SHOW_MESSAGE_SUCCESS = '[MESSAGE] Show message success',
    SHOW_MESSAGE_FAILURE = '[MESSAGE] Show message failure',

    RESET_MESSAGE = '[MESSAGE] Reset message',
}

export const showMessageAction = createAction(
    MessageActionsEnum.SHOW_MESSAGE,
    props<{ id: number }>(),
);
export const showMessageSuccessAction = createAction(
    MessageActionsEnum.SHOW_MESSAGE_SUCCESS,
    props<ResponseResource<Message>>(),
);
export const showMessageFailureAction = createAction(
    MessageActionsEnum.SHOW_MESSAGE_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetMessageAction = createAction(
    MessageActionsEnum.RESET_MESSAGE,
);
