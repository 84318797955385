import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HeaderService } from '@utility/header/header.service';
import { MenuService } from '@utility/menu/menu.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ColorEnum } from '@core/enums/colors.enum';
import { User } from '@utility/user/user';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
    selectAuthenticatedUser,
    selectAuthenticated,
} from '@selector/authentication/authentication.selectors';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    public title$: Observable<string>;
    public subTitle$: Observable<string>;
    public color$: Observable<string>;
    public textColor$: Observable<string>;
    public authenticatedUser$: Observable<User>;
    public isAuthenticated$: Observable<boolean>;
    public isDashboard$: Observable<boolean>;
    public shadowless$: Observable<boolean>;
    public hideMenuButton$: Observable<boolean>;

    private readonly isDashboardSubject$: BehaviorSubject<boolean>;

    public ColorEnum = ColorEnum;

    constructor(
        private readonly menuService: MenuService,
        private readonly headerService: HeaderService,
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly location: Location,
    ) {
        this.isDashboardSubject$ = new BehaviorSubject(false);
        this.isDashboard$ = this.isDashboardSubject$.asObservable();
    }

    /**
     * On component init lifecycle event.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public ngOnInit(): void {
        this.title$ = this.headerService.title$;
        this.subTitle$ = this.headerService.subTitle$;
        this.color$ = this.headerService.color$;
        this.textColor$ = this.headerService.textColor$;
        this.shadowless$ = this.headerService.shadowless$;
        this.hideMenuButton$ = this.headerService.hideMenuButton$;
        this.authenticatedUser$ = this.store.select(selectAuthenticatedUser);
        this.isAuthenticated$ = this.store.select(selectAuthenticated);

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.isDashboardSubject$.next(event.url === '/dashboard');
            });
    }

    /**
     * Toggle menu status.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public toggleMenu(): void {
        this.menuService.toggleStatus();
    }

    /**
     * On navigate back click action.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public navigateBack(): void {
        this.location.back();
    }
}
