import { createAction, props } from '@ngrx/store';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { Activity } from '@utility/activity/activity';
import { IndexActivitiesRequestProps } from '@utility/activity/index-activities-request';

export enum ActivitiesActionsEnum {
    LoadActivities = '[ACTIVITIES] Load activities',
    LoadActivitiesSuccess = '[ACTIVITIES] Load activities success',
    LoadActivitiesFailure = '[ACTIVITIES] Load activities failure',

    ResetActivities = '[ACTIVITIES] Reset activities',
}

export const loadActivitiesAction = createAction(
    ActivitiesActionsEnum.LoadActivities,
    props<IndexActivitiesRequestProps>(),
);

export const loadActivitiesSuccessAction = createAction(
    ActivitiesActionsEnum.LoadActivitiesSuccess,
    props<ResponseResourceCollection<Activity>>(),
);

export const loadActivitiesFailureAction = createAction(
    ActivitiesActionsEnum.LoadActivitiesFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetActivitiesAction = createAction(
    ActivitiesActionsEnum.ResetActivities,
);
