import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopEnlistmentService } from '@utility/workshop-enlistment/workshop-enlistment.service';
import { IndexWorkshopEnlistmentsProps } from '@utility/workshop-enlistment/Index-workshop-enlistments-request';
import {
    loadWorkshopEnlistmentsFailureAction,
    loadWorkshopEnlistmentsSuccessAction,
    WorkshopEnlistmentsActionsEnum,
} from '@action/workshop-enlistments/workshop-enlistments.actions';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';

@Injectable({ providedIn: 'root' })
export class WorkshopsEnlistmentsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopEnlistmentService: WorkshopEnlistmentService,
    ) {}

    /**
     * Create workshop enlistments effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadWorkshopEnlistmentsEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopEnlistmentsActionsEnum.LoadWorkshopEnlistments),
            mergeMap((data: IndexWorkshopEnlistmentsProps) =>
                this.workshopEnlistmentService.index(data.payload).pipe(
                    map(
                        (
                            response: ResponseResourceCollection<WorkshopEnlistment>,
                        ) => loadWorkshopEnlistmentsSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadWorkshopEnlistmentsFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
