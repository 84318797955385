import {
    resetWorkshopEnlistmentAction,
    storeWorkshopEnlistmentAction,
    storeWorkshopEnlistmentFailureAction,
    storeWorkshopEnlistmentSuccessAction,
} from '@action/workshop-enlistments/workshop-enlistment.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseStoreItemFailureReducer,
    baseStoreItemReducer,
    baseStoreItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';

export const workshopEnlistmentReducer = (
    itemState: ItemState<WorkshopEnlistment>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<WorkshopEnlistment>(),
        on(storeWorkshopEnlistmentAction, state => baseStoreItemReducer(state)),
        on(storeWorkshopEnlistmentSuccessAction, (state, response) =>
            baseStoreItemSuccessReducer<WorkshopEnlistment>(state, response),
        ),
        on(storeWorkshopEnlistmentFailureAction, (state, response) =>
            baseStoreItemFailureReducer<WorkshopEnlistment>(
                state,
                response.error,
            ),
        ),
        on(resetWorkshopEnlistmentAction, () =>
            baseResetItemReducer<WorkshopEnlistment>(),
        ),
    )(itemState, action);
