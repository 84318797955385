import {
    loadWorkshopEnlistmentsAction,
    loadWorkshopEnlistmentsFailureAction,
    loadWorkshopEnlistmentsSuccessAction,
    resetWorkshopEnlistmentsAction,
} from '@action/workshop-enlistments/workshop-enlistments.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';

export const workshopEnlistmentsReducer = (
    listState: ListState<WorkshopEnlistment>,
    action: Action,
) =>
    createReducer(
        getInitialListState<WorkshopEnlistment>(),
        on(loadWorkshopEnlistmentsAction, state => baseLoadListReducer(state)),
        on(loadWorkshopEnlistmentsSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(loadWorkshopEnlistmentsFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetWorkshopEnlistmentsAction, () =>
            baseResetListReducer<WorkshopEnlistment>(),
        ),
    )(listState, action);
