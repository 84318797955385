import {
    resetUserPaymentAction,
    updateUserPaymentAction,
    updateUserPaymentFailureAction,
    updateUserPaymentSuccessAction,
} from '@action/user-payments/user-payment.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseUpdateItemFailureReducer,
    baseUpdateItemReducer,
    baseUpdateItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { UserPayment } from '@utility/user-payment/user-payment';

export const userPaymentReducer = (
    itemState: ItemState<UserPayment>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<UserPayment>(),
        on(updateUserPaymentAction, state => baseUpdateItemReducer(state)),
        on(updateUserPaymentSuccessAction, (state, response) =>
            baseUpdateItemSuccessReducer<UserPayment>(state, response),
        ),
        on(updateUserPaymentFailureAction, (state, response) =>
            baseUpdateItemFailureReducer<UserPayment>(state, response.error),
        ),

        on(resetUserPaymentAction, () => baseResetItemReducer<UserPayment>()),
    )(itemState, action);
