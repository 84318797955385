import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPaymentService } from '@utility/user-payment/user-payment.service';
import {
    indexUserPaymentsFailureAction,
    indexUserPaymentsSuccessAction,
    UserPaymentsActionsEnum,
} from '@action/user-payments/user-payments.actions';
import { UserPayment } from '@utility/user-payment/user-payment';
import { IndexUserPaymentsProps } from '@utility/user-payment/index-user-payments-request';

@Injectable({ providedIn: 'root' })
export class UserPaymentsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userPaymentService: UserPaymentService,
    ) {}

    /**
     * Create index user payments effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public indexUserPaymentsEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPaymentsActionsEnum.IndexUserPayments),
            mergeMap((props: IndexUserPaymentsProps) =>
                this.userPaymentService.index(props.payload).pipe(
                    map((response: ResponseResourceCollection<UserPayment>) =>
                        indexUserPaymentsSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(indexUserPaymentsFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
