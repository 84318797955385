import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UpdateService } from '@utility/update/update.service';

@Component({
    selector: 'app-update-modal',
    templateUrl: 'update-modal.component.html',
    styleUrls: ['update-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateModalComponent {
    constructor(private readonly updateService: UpdateService) {}

    /**
     * On confirm action.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public confirmAction(): void {
        this.updateService.reload();
    }

    /**
     * On cancel action.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public cancelAction(): void {
        this.updateService.cancelUpdate();
    }
}
