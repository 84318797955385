import { AlertState, AlertProps } from '@state/alert.state';
import { Action, createReducer, on } from '@ngrx/store';
import { initialAlertState } from '@core/states/alert.state';
import {
    removeAlertAction,
    removeAllAlertAction,
    setAlertAction,
} from '@action/alert/alert.actions';

export const alertReducer = (alertState: AlertState, action: Action) =>
    createReducer(
        initialAlertState,
        on(
            setAlertAction,
            (state: AlertState, props: { payload: AlertProps }) => ({
                ...state,
                [props.payload.key]: props.payload,
            }),
        ),
        on(removeAlertAction, (state: AlertState, payload: { key: string }) => {
            const newState = { ...state };
            delete newState[payload.key];

            return newState;
        }),
        on(removeAllAlertAction, () => initialAlertState),
    )(alertState, action);
