import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Move } from '@utility/move/move';

export enum MoveActionsEnum {
    SHOW_MOVE = '[MOVE] Show move',
    SHOW_MOVE_SUCCESS = '[MOVE] Show move success',
    SHOW_MOVE_FAILURE = '[MOVE] Show move failure',

    RESET_MOVE = '[MOVE] Reset move',
}

export const showMoveAction = createAction(
    MoveActionsEnum.SHOW_MOVE,
    props<{ slug: string }>(),
);
export const showMoveSuccessAction = createAction(
    MoveActionsEnum.SHOW_MOVE_SUCCESS,
    props<ResponseResource<Move>>(),
);
export const showMoveFailureAction = createAction(
    MoveActionsEnum.SHOW_MOVE_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetMoveAction = createAction(MoveActionsEnum.RESET_MOVE);
