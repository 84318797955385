import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FooterService {
    public visibility$: Observable<boolean>;

    private readonly visibilitySubject$: BehaviorSubject<boolean>;

    constructor() {
        this.visibilitySubject$ = new BehaviorSubject(true);
        this.visibility$ = this.visibilitySubject$.asObservable();
    }

    /**
     * Set footer visibility.
     *
     * @param value
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public setVisibility(value: boolean): void {
        this.visibilitySubject$.next(value);
    }
}
