import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MoveService } from '@utility/move/move.service';
import {
    loadMovesFailureAction,
    loadMovesSuccessAction,
    MovesActionsEnum,
} from '@action/moves/moves.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MovesIndexRequestProps } from '@utility/move/moves-index-request';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { Move } from '@utility/move/move';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MovesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly moveService: MoveService,
    ) {}

    /**
     * Create moves effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadMovesEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MovesActionsEnum.LoadMoves),
            mergeMap((data: MovesIndexRequestProps) =>
                this.moveService.index(data.payload).pipe(
                    map((response: ResponseResourceCollection<Move>) =>
                        loadMovesSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadMovesFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
