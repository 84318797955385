import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { WorkshopDateService } from '@utility/workshop-date/workshop-date.service';
import {
    deleteWorkshopDateFailureAction,
    deleteWorkshopDateSuccessAction,
    storeWorkshopDateFailureAction,
    WorkshopDateActionsEnum,
} from '@action/workshop-dates/workshop-date.actions';
import { StoreWorkshopDateProps } from '@utility/workshop-date/store-workshop-date-request';
import { storeWorkshopDateSuccessAction } from '@action/workshop-dates/workshop-date.actions';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { SendWorkshopDateEmailRequestProps } from '../../core/utilities/workshop-date/send-workshop-date-email-request';
import {
    insertWorkshopDateAction,
    removeWorkshopDateAction,
} from '@action/workshop-dates/workshop-dates.actions';

@Injectable({ providedIn: 'root' })
export class WorkshopDateEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopDateService: WorkshopDateService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Create show workshop effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storeWorkshopDateEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopDateActionsEnum.STORE_WORKSHOP_DATE),
            mergeMap((props: StoreWorkshopDateProps) =>
                this.workshopDateService.store(props.payload).pipe(
                    map((response: ResponseResource<WorkshopDate>) => {
                        this.store.dispatch(
                            insertWorkshopDateAction({
                                payload: response.data,
                            }),
                        );

                        return storeWorkshopDateSuccessAction(response);
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(storeWorkshopDateFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create show workshop effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public deleteWorkshopDateEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopDateActionsEnum.DELETE_WORKSHOP_DATE),
            mergeMap((props: { workshopDateId: number }) =>
                this.workshopDateService.delete(props.workshopDateId).pipe(
                    map(() => {
                        this.store.dispatch(
                            removeWorkshopDateAction({
                                workshopDateId: props.workshopDateId,
                            }),
                        );

                        return deleteWorkshopDateSuccessAction();
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(deleteWorkshopDateFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );

    public sendWorkshopDateEmailEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopDateActionsEnum.SEND_WORKSHOP_DATE_EMAIL),
            mergeMap((props: SendWorkshopDateEmailRequestProps) =>
                this.workshopDateService.sendMail(props.payload).pipe(
                    map(() => {
                        return deleteWorkshopDateSuccessAction();
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(deleteWorkshopDateFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
