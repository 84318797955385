import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '@utility/http/api.service';
import { StoreWorkshopRequest } from './store-workshop-request';
import { UpdateWorkshopRequest } from './update-workshop-request';
import { Workshop } from './workshop';
import { WorkshopsIndexRequest } from './workshops-index-request';

@Injectable({ providedIn: 'root' })
export class WorkshopsService extends ApiService<
    Workshop,
    WorkshopsIndexRequest,
    Params,
    StoreWorkshopRequest,
    UpdateWorkshopRequest
> {
    protected baseUrl = 'workshops';
}
