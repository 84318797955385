import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HeaderModule } from '@core/components/header/header.module';
import { FooterModule } from '@core/components/footer/footer.module';
import { MenuModule } from '@core/components/menu/menu.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import Bugsnag from '@bugsnag/js';
import { UpdateModalModule } from '@core/components/update-modal/update-modal.module';
import { ConfirmationModalModule } from './core/components/confirmation-modal/confirmation-modal.module';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { GlobalErrorHandler } from '@utility/error-handler/error-handler';

// configure Bugsnag asap
Bugsnag.start({ apiKey: environment.bugsnagKey });

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CoreModule,
        AppRoutingModule,
        MenuModule,
        HeaderModule,
        FooterModule,
        UpdateModalModule,
        ConfirmationModalModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        NgxMatomoTrackerModule.forRoot({
            trackerUrl: environment.matomoTrackerUrl,
            siteId: environment.matomoSiteId,
            acceptDoNotTrack: true,
        }),
        NgxMatomoRouterModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
