import { AuthenticationCredentials } from '@utility/authentication/authentication-credentials';
import { User } from '@utility/user/user';
import { HttpErrorResponse } from '@angular/common/http';

export interface AuthenticationState {
    authenticationCredentials?: AuthenticationCredentials;
    authenticatedUser?: User;

    authenticated: boolean;
    authenticationChecked: boolean;

    loginError?: HttpErrorResponse;
    refreshError?: HttpErrorResponse;
    checkError?: HttpErrorResponse;

    loginPending: boolean;
    refreshPending: boolean;
    checkPending: boolean;
}

export const initialAuthenticationState: AuthenticationState = {
    authenticationCredentials: null,
    authenticatedUser: null,

    authenticated: false,
    authenticationChecked: false,

    loginError: null,
    refreshError: null,
    checkError: null,

    loginPending: false,
    refreshPending: false,
    checkPending: false,
};
