import { ApiService } from '@utility/http/api.service';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';
import { IndexWorkshopDateRequest } from './index-workshop-dates-request';
import { Params } from '@angular/router';
import { StoreWorkshopDateRequest } from '@utility/workshop-date/store-workshop-date-request';
import { Injectable } from '@angular/core';
import { SendWorkshopDateEmailRequest } from './send-workshop-date-email-request';

@Injectable({ providedIn: 'root' })
export class WorkshopDateService extends ApiService<
    WorkshopDate,
    IndexWorkshopDateRequest,
    Params,
    StoreWorkshopDateRequest
> {
    protected baseUrl = 'workshop-dates';

    public sendMail(payload: SendWorkshopDateEmailRequest) {
        return this.httpService.post(
            `${this.baseUrl}/${payload.workshopDateId}/send-mail`,
            payload,
        );
    }
}
