import {
    showMoveAction,
    showMoveFailureAction,
    showMoveSuccessAction,
    resetMoveAction,
} from '@action/moves/move.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Move } from '@utility/move/move';

export const moveReducer = (itemState: ItemState<Move>, action: Action) =>
    createReducer(
        getInitialItemState<Move>(),
        on(showMoveAction, state => baseShowItemReducer(state)),
        on(showMoveSuccessAction, (state, response) =>
            baseShowItemSuccessReducer<Move>(state, response),
        ),
        on(showMoveFailureAction, (state, response) =>
            baseShowItemFailureReducer<Move>(state, response.error),
        ),
        on(resetMoveAction, () => baseResetItemReducer<Move>()),
    )(itemState, action);
