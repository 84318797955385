import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
    /**
     * Add language header to request.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(
            req.clone({
                headers: req.headers.set(
                    'Accept-Language',
                    environment.language,
                ),
            }),
        );
    }
}
