import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '@utility/http/api.service';
import { Set } from './set';
import { SetsIndexRequest } from './sets-index-request';

@Injectable({ providedIn: 'root' })
export class SetsService extends ApiService<Set, SetsIndexRequest, Params> {
    protected baseUrl = 'sets';
}
