import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuStatus } from '@utility/menu/menu-status.enum';
import { MenuService } from '@utility/menu/menu.service';
import { Observable } from 'rxjs';
import { User } from '@utility/user/user';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { UnreadMessagesCount } from '@utility/message/unread-messages-count';
import { selectAuthenticatedUser } from '@selector/authentication/authentication.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    public menuStatus$: Observable<MenuStatus>;
    public unreadMessagesCount$: Observable<UnreadMessagesCount>;
    public authenticatedUser$: Observable<User>;

    public shareAvailable;

    public MenuStatus = MenuStatus;

    constructor(
        private readonly menuService: MenuService,
        private readonly store: Store<AppState>,
        private readonly translateService: TranslateService,
    ) {}

    /**
     * On component init lifecycle event.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public ngOnInit(): void {
        this.authenticatedUser$ = this.store.select(selectAuthenticatedUser);
        this.menuStatus$ = this.menuService.menuStatus$;
        this.unreadMessagesCount$ = this.store.select(
            (state: AppState) => state.messagesCountState.data,
        );

        this.shareAvailable = !!navigator.share;
    }
    /**
     * Close menu.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public closeMenu(): void {
        this.menuService.changeStatus(MenuStatus.Closed);
    }

    /**
     * Share application.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public async shareApplication(): Promise<void> {
        if (!navigator.share) {
            return;
        }

        await navigator.share({
            title: this.translateService.instant('shared.share.title'),
            text: this.translateService.instant('shared.share.text'),
            url: this.translateService.instant('shared.share.url'),
        });
    }
}
