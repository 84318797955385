import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { WorkshopDate } from '@utility/workshop-date/workshop-date';
import { StoreWorkshopDateProps } from '@utility/workshop-date/store-workshop-date-request';
import { props, createAction } from '@ngrx/store';
import { SendWorkshopDateEmailRequestProps } from '@utility/workshop-date/send-workshop-date-email-request';

export enum WorkshopDateActionsEnum {
    STORE_WORKSHOP_DATE = '[WORKSHOP DATE] Store workshop date',
    STORE_WORKSHOP_DATE_SUCCESS = '[WORKSHOP DATE] Store workshop date success',
    STORE_WORKSHOP_DATE_FAILURE = '[WORKSHOP DATE] Store workshop date failure',

    DELETE_WORKSHOP_DATE = '[WORKSHOP DATE] Delete workshop date',
    DELETE_WORKSHOP_DATE_SUCCESS = '[WORKSHOP DATE] Delete workshop date success',
    DELETE_WORKSHOP_DATE_FAILURE = '[WORKSHOP DATE] Delete workshop date failure',

    SEND_WORKSHOP_DATE_EMAIL = '[WORKSHOP DATE] Send workshop date email',
    SEND_WORKSHOP_DATE_EMAIL_SUCCESS = '[WORKSHOP DATE] Send workshop date email success',
    SEND_WORKSHOP_DATE_EMAIL_FAILURE = '[WORKSHOP DATE] Send workshop date email failure',
}

export const storeWorkshopDateAction = createAction(
    WorkshopDateActionsEnum.STORE_WORKSHOP_DATE,
    props<StoreWorkshopDateProps>(),
);
export const storeWorkshopDateSuccessAction = createAction(
    WorkshopDateActionsEnum.STORE_WORKSHOP_DATE_SUCCESS,
    props<ResponseResource<WorkshopDate>>(),
);
export const storeWorkshopDateFailureAction = createAction(
    WorkshopDateActionsEnum.STORE_WORKSHOP_DATE_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteWorkshopDateAction = createAction(
    WorkshopDateActionsEnum.DELETE_WORKSHOP_DATE,
    props<{ workshopDateId: number }>(),
);
export const deleteWorkshopDateSuccessAction = createAction(
    WorkshopDateActionsEnum.DELETE_WORKSHOP_DATE_SUCCESS,
);
export const deleteWorkshopDateFailureAction = createAction(
    WorkshopDateActionsEnum.DELETE_WORKSHOP_DATE_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const sendWorkshopDateEmailAction = createAction(
    WorkshopDateActionsEnum.SEND_WORKSHOP_DATE_EMAIL,
    props<SendWorkshopDateEmailRequestProps>(),
);
export const sendWorkshopDateEmailSuccessAction = createAction(
    WorkshopDateActionsEnum.SEND_WORKSHOP_DATE_EMAIL_SUCCESS,
);
export const sendWorkshopDateEmailFailureAction = createAction(
    WorkshopDateActionsEnum.SEND_WORKSHOP_DATE_EMAIL_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);
