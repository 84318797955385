import { createAction, props } from '@ngrx/store';
import { ResponseResource } from '@utility/http/response-resource';
import { User } from '@utility/user/user';
import { HttpErrorResponse } from '@angular/common/http';
import { StoreUserRequestProps } from '@utility/user/store-user-request';
import { UpdateUserRequestProps } from '@utility/user/update-user-request';
import { SubscriptionPayment } from '@utility/subscription/subscription-payment';
import { ActivateUserProps } from '@utility/user/activate-user-request';
import { UpdateBillingRequestProps } from '@utility/user/update-billing-request';

export enum UserActionsEnum {
    STORE_USER = '[USER] Store user',
    STORE_USER_SUCCESS = '[USER] Store user success',
    STORE_USER_FAILURE = '[USER] Store user failure',

    UPDATE_USER = '[USER] Update user',
    UPDATE_USER_SUCCESS = '[USER] Update user success',
    UPDATE_USER_FAILURE = '[USER] Update user failure',

    CANCEL_SUBSCRIPTION = '[USER] Cancel subscription',
    CANCEL_SUBSCRIPTION_SUCCESS = '[USER] Cancel subscription success',
    CANCEL_SUBSCRIPTION_FAILURE = '[USER] Cancel subscription failure',

    START_SUBSCRIPTION = '[USER] Start subscription',
    START_SUBSCRIPTION_SUCCESS = '[USER] Start subscription success',
    START_SUBSCRIPTION_FAILURE = '[USER] Start subscription failure',

    ACTIVATE_USER = '[USER] Activate user',
    ACTIVATE_USER_SUCCESS = '[USER] Activate user success',
    ACTIVATE_USER_FAILURE = '[USER] Activate user failure',

    UPDATE_USER_BILLING = '[USER] Update user billing',
    UPDATE_USER_BILLING_SUCCESS = '[USER] Update user billing success',
    UPDATE_USER_BILLING_FAILURE = '[USER] Update user billing failure',

    RESET_USER = '[USER] Reset user',
    RESET_USER_IS_SUCCESSFUL = '[USER] Reset user is successful',
}

export const storeUserAction = createAction(
    UserActionsEnum.STORE_USER,
    props<StoreUserRequestProps>(),
);
export const storeUserSuccessAction = createAction(
    UserActionsEnum.STORE_USER_SUCCESS,
    props<ResponseResource<User>>(),
);
export const storeUserFailureAction = createAction(
    UserActionsEnum.STORE_USER_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const updateUserAction = createAction(
    UserActionsEnum.UPDATE_USER,
    props<UpdateUserRequestProps>(),
);
export const updateUserSuccessAction = createAction(
    UserActionsEnum.UPDATE_USER_SUCCESS,
    props<ResponseResource<User>>(),
);
export const updateUserFailureAction = createAction(
    UserActionsEnum.UPDATE_USER_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const cancelSubscriptionAction = createAction(
    UserActionsEnum.CANCEL_SUBSCRIPTION,
);
export const cancelSubscriptionSuccessAction = createAction(
    UserActionsEnum.CANCEL_SUBSCRIPTION_SUCCESS,
);
export const cancelSubscriptionFailureAction = createAction(
    UserActionsEnum.CANCEL_SUBSCRIPTION_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const startSubscriptionAction = createAction(
    UserActionsEnum.START_SUBSCRIPTION,
    props<{ payload: { couponCode?: string } }>(),
);
export const startSubscriptionSuccessAction = createAction(
    UserActionsEnum.START_SUBSCRIPTION_SUCCESS,
    props<ResponseResource<SubscriptionPayment>>(),
);
export const startSubscriptionFailureAction = createAction(
    UserActionsEnum.START_SUBSCRIPTION_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const activateUserAction = createAction(
    UserActionsEnum.ACTIVATE_USER,
    props<ActivateUserProps>(),
);
export const activateUserSuccessAction = createAction(
    UserActionsEnum.ACTIVATE_USER_SUCCESS,
    props<ResponseResource<User>>(),
);
export const activateUserFailureAction = createAction(
    UserActionsEnum.ACTIVATE_USER_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const updateUserBillingAction = createAction(
    UserActionsEnum.UPDATE_USER_BILLING,
    props<UpdateBillingRequestProps>(),
);
export const updateUserBillingSuccessAction = createAction(
    UserActionsEnum.UPDATE_USER_BILLING_SUCCESS,
    props<ResponseResource<User>>(),
);
export const updateUserBillingFailureAction = createAction(
    UserActionsEnum.UPDATE_USER_BILLING_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetUserAction = createAction(UserActionsEnum.RESET_USER);
export const resetUserIsSuccessfulAction = createAction(
    UserActionsEnum.RESET_USER_IS_SUCCESSFUL,
);
