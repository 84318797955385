import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationService } from '@utility/confirmation/confirmation.service';
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: 'confirmation-modal.component.html',
    styleUrls: ['confirmation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit {
    public question$: Observable<string>;
    public confirmText$: Observable<string | null>;

    constructor(private readonly confirmationService: ConfirmationService) {}

    /**
     * On component init lifecycle event.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public ngOnInit(): void {
        this.question$ = this.confirmationService.question$;
        this.confirmText$ = this.confirmationService.confirmText$;
    }

    public confirmAction(): void {
        this.confirmationService.onConfirm();
    }

    public cancelAction(): void {
        this.confirmationService.onCancel();
    }
}
