export enum ColorEnum {
    White = '#ffffff',
    DarkWhite = '#e5e1e1',
    Black = '#212227',
    Avium = '#df4d8d',
    Cloak = '#6b6362',
    Fields = '#5add33',
    Red = '#f42b03',
    Jazzy = '#55d6c2',
}
