import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as camelcaseKeys from 'camelcase-keys';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ToCamelCaseInterceptor implements HttpInterceptor {
    /**
     * Convert response body to camel case.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map(response => {
                if (response instanceof HttpResponse && response.body?.data) {
                    return response.clone({
                        body: camelcaseKeys(response.body, { deep: true }),
                    });
                }

                return response;
            }),
        );
    }
}
