import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { UserPayment } from '@utility/user-payment/user-payment';
import { UpdateUserPaymentProps } from '@utility/user-payment/update-user-payment-request';

export enum UserPaymentActionsEnum {
    UpdateUserPayment = '[USER PAYMENT] Update user payment',
    UpdateUserPaymentSuccess = '[USER PAYMENT] Update user payment success',
    UpdateUserPaymentFailure = '[USER PAYMENT] Update user payment failure',

    ResetUserPayment = '[USER PAYMENT] Reset user payment',
}

export const updateUserPaymentAction = createAction(
    UserPaymentActionsEnum.UpdateUserPayment,
    props<UpdateUserPaymentProps>(),
);
export const updateUserPaymentSuccessAction = createAction(
    UserPaymentActionsEnum.UpdateUserPaymentSuccess,
    props<ResponseResource<UserPayment>>(),
);
export const updateUserPaymentFailureAction = createAction(
    UserPaymentActionsEnum.UpdateUserPaymentFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetUserPaymentAction = createAction(
    UserPaymentActionsEnum.ResetUserPayment,
);
