import { AlertTypeEnum } from '@core/enums/alert-type.enum';

export interface AlertProps {
    key: string;
    type: AlertTypeEnum;
    message: string;
}

export interface AlertState {
    [key: string]: AlertProps;
}

export const initialAlertState: AlertState = {};
