import { Injectable } from '@angular/core';
import { ApiService } from '@utility/http/api.service';
import { Message } from '@utility/message/message';
import { MessagesIndexRequest } from './messages-index-request';
import { Observable } from 'rxjs';
import { ResponseResource } from '@utility/http/response-resource';
import { UnreadMessagesCount } from './unread-messages-count';

@Injectable({ providedIn: 'root' })
export class MessageService extends ApiService<Message, MessagesIndexRequest> {
    protected baseUrl = 'messages';

    /**
     * Count unread messages.
     *
     * @returns Observable.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public count(): Observable<ResponseResource<UnreadMessagesCount>> {
        return this.httpService.get(`${this.baseUrl}/count`);
    }
}
