import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticationCredentials } from '@utility/authentication/authentication-credentials';
import { User } from '@utility/user/user';
import { HttpErrorResponse } from '@angular/common/http';
import {
    loginAction,
    loginFailureAction,
    loginSuccessAction,
    refreshAuthenticationAction,
    refreshAuthenticationFailureAction,
    refreshAuthenticationSuccessAction,
    checkAuthenticationAction,
    checkAuthenticationSuccessAction,
    checkAuthenticationFailureAction,
    logoutAction,
    updateAuthenticatedUserPropAction,
} from '@action/authentication/authentication.actions';
import { ResponseResource } from '@utility/http/response-resource';
import { setAuthenticatedUserAction } from '@action/authentication/authentication.actions';
import {
    AuthenticationState,
    initialAuthenticationState,
} from '@state/authentication.state';

export const authenticationReducer = (
    authenticationState: AuthenticationState,
    action: Action,
) =>
    createReducer(
        initialAuthenticationState,

        on(loginAction, (state: AuthenticationState) => ({
            ...state,
            authenticationCredentials: null,
            loginPending: true,
            loginError: null,
        })),
        on(
            loginSuccessAction,
            (
                state: AuthenticationState,
                response: ResponseResource<AuthenticationCredentials>,
            ) => ({
                ...state,
                authenticationCredentials: response.data,
                loginPending: false,
            }),
        ),
        on(
            loginFailureAction,
            (
                state: AuthenticationState,
                response: { error: HttpErrorResponse },
            ) => ({
                ...state,
                authenticationCredentials: null,
                loginPending: false,
                loginError: response.error,
            }),
        ),

        on(refreshAuthenticationAction, (state: AuthenticationState) => ({
            ...state,
            authenticationCredentials: null,
            refreshPending: true,
            refreshError: null,
        })),
        on(
            refreshAuthenticationSuccessAction,
            (
                state: AuthenticationState,
                response: ResponseResource<AuthenticationCredentials>,
            ) => ({
                ...state,
                authenticationCredentials: response.data,
                refreshPending: false,
            }),
        ),
        on(
            refreshAuthenticationFailureAction,
            (
                state: AuthenticationState,
                response: { error: HttpErrorResponse },
            ) => ({
                ...state,
                authenticationCredentials: null,
                refreshPending: false,
                refreshError: response.error,
            }),
        ),

        on(checkAuthenticationAction, (state: AuthenticationState) => ({
            ...state,
            authenticatedUser: null,
            authenticated: false,
            checkPending: true,
            checkError: null,
        })),
        on(
            checkAuthenticationSuccessAction,
            (state: AuthenticationState, response: ResponseResource<User>) => ({
                ...state,
                authenticatedUser: response.data,
                authenticated: true,
                checkPending: false,
                authenticationChecked: true,
            }),
        ),
        on(
            checkAuthenticationFailureAction,
            (
                state: AuthenticationState,
                response: { error: HttpErrorResponse },
            ) => ({
                ...state,
                authenticatedUser: null,
                authenticated: false,
                checkPending: false,
                checkError: response.error,
            }),
        ),
        on(
            setAuthenticatedUserAction,
            (state: AuthenticationState, payload: { user: User }) => ({
                ...state,
                authenticatedUser: payload.user,
            }),
        ),
        on(
            updateAuthenticatedUserPropAction,
            (
                state: AuthenticationState,
                payload: { key: string; value?: string },
            ) => ({
                ...state,
                authenticatedUser: {
                    ...state.authenticatedUser,
                    [payload.key]: payload.value,
                },
            }),
        ),

        on(logoutAction, () => initialAuthenticationState),
    )(authenticationState, action);
