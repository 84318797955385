import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseResource } from '@utility/http/response-resource';
import { HttpService } from '@utility/http/http.service';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import snakecaseKeys from 'snakecase-keys';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ApiService<
    T,
    IndexGeneric = Params,
    ShowGeneric = Params,
    StoreGeneric = Params,
    UpdateGeneric = Params,
> {
    protected baseUrl: string;

    constructor(protected readonly httpService: HttpService) {}

    /**
     * Index api resource.
     *
     * @author Niek van der Velde <niek@velde.xyz>
     * @version 1.0.0
     */
    public index(
        request: IndexGeneric,
    ): Observable<ResponseResourceCollection<T>> {
        return this.httpService.get(
            this.baseUrl,
            snakecaseKeys(request as any),
        );
    }

    /**
     * Show api resource.
     *
     * @author Niek van der Velde <niek@velde.xyz>
     * @version 1.0.0
     */
    public show(
        identifier?: number | string,
        queryParams?: ShowGeneric,
    ): Observable<ResponseResource<T>> {
        return this.httpService.get(
            `${this.baseUrl}${identifier ? `/${identifier}` : ''}`,
            queryParams ? snakecaseKeys(queryParams as any) : queryParams,
        );
    }

    /**
     * Perform store request to api.
     *
     * @param payload
     *
     * @returns Observable of response.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public store(payload: StoreGeneric): Observable<ResponseResource<T>> {
        return this.httpService.post(this.baseUrl, payload);
    }

    /**
     * Perform update request to api.
     *
     * @param payload
     *
     * @returns Observable of response.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public update(
        payload: UpdateGeneric,
        identifier?: string | number,
    ): Observable<ResponseResource<T>> {
        return this.httpService.put(
            this.baseUrl + (identifier ? `/${identifier}` : ''),
            payload,
        );
    }

    /**
     * Perform delete request to api.
     *
     * @param [identifier]
     *
     * @returns Observable of response.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public delete(identifier?: number | string): Observable<void> {
        return this.httpService.delete(
            `${this.baseUrl}${identifier ? `/${identifier}` : ''}`,
        );
    }
}
