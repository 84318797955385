import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { QuotesService } from '@utility/quotes/quotes.service';
import {
    QuoteActionsEnum,
    showQuoteFailureAction,
    showQuoteSuccessAction,
} from '@action/quotes/quote.actions';
import { ResponseResource } from '@utility/http/response-resource';
import { Quote } from '@utility/quotes/quote';

@Injectable({ providedIn: 'root' })
export class QuoteEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly quotesService: QuotesService,
    ) {}

    /**
     * Create quote effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public showQuoteEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QuoteActionsEnum.ShowQuote),
            mergeMap(() =>
                this.quotesService.show().pipe(
                    map((response: ResponseResource<Quote>) =>
                        showQuoteSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(showQuoteFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
