import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthenticationGuard } from '@shared/guards/authentication.guard';
import { GuestGuard } from '@shared/guards/guest.guard';
import { GuestOrAuthenticatedGuard } from '@shared/guards/quest-or-authenticated.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: async () =>
            (await import('./feature/home/home.module')).HomeModule,
        canActivate: [GuestGuard],
    },
    {
        path: 'register/confirmation',
        loadChildren: async () =>
            (
                await import(
                    './feature/home/home-register-confirmation/home-register-confirmation.module'
                )
            ).HomeRegisterConfirmationModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'register/payment',
        loadChildren: async () =>
            (
                await import(
                    './feature/home/home-register-payment/home-register-payment.module'
                )
            ).HomeRegisterPaymentModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'email-confirmation',
        loadChildren: async () =>
            (
                await import(
                    './feature/home/home-email-confirmation/home-email-confirmation.module'
                )
            ).HomeEmailConfirmationModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
    {
        path: 'dashboard',
        loadChildren: async () =>
            (await import('./feature/dashboard/dashboard.module'))
                .DashboardModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'playlists',
        loadChildren: async () =>
            (await import('./feature/playlists/playlists.module'))
                .PlaylistsModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'moves',
        loadChildren: async () =>
            (await import('./feature/moves/moves.module')).MovesModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'evaluation',
        loadChildren: async () =>
            (await import('./feature/evaluation/evaluation.module'))
                .EvaluationModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'activities',
        loadChildren: async () =>
            (await import('./feature/activities/activities.module'))
                .ActivitiesModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'sets',
        loadChildren: async () =>
            (await import('./feature/sets/sets.module')).SetsModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'settings',
        loadChildren: async () =>
            (await import('./feature/settings/settings.module')).SettingsModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'messages',
        loadChildren: async () =>
            (await import('./feature/messages/messages.module')).MessagesModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'payment-required',
        loadChildren: async () =>
            (
                await import(
                    './feature/catchers/payment-required/payment-required.module'
                )
            ).PaymentRequiredModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'move-buddies',
        loadChildren: async () =>
            (await import('./feature/move-buddies/move-buddies.module'))
                .MoveBuddiesModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'not-found',
        loadChildren: async () =>
            (await import('./feature/catchers/not-found/not-found.module'))
                .NotFoundModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
    {
        path: 'subscription',
        loadChildren: async () =>
            (await import('./feature/subscription/subscription.module'))
                .SubscriptionModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'workshops',
        loadChildren: async () =>
            (await import('./feature/workshops/workshops.module'))
                .WorkshopsModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'tour',
        loadChildren: async () =>
            (await import('./feature/tour/tour.module')).TourStartModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
    {
        path: 'terms-and-conditions',
        loadChildren: async () =>
            (
                await import(
                    './feature/terms-and-conditions/terms-and-conditions.module'
                )
            ).TermsAndConditionsModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
    {
        path: 'privacy-statement',
        loadChildren: async () =>
            (
                await import(
                    './feature/privacy-statement/privacy-statement.module'
                )
            ).PrivacyStatementModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
    {
        path: 'introduction',
        loadChildren: async () =>
            (
                await import(
                    './feature/home/home-introduction-video/home-introduction-video.module'
                )
            ).HomeIntroductionVideoModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
    {
        path: 'user-payments',
        loadChildren: async () =>
            (
                await import(
                    './feature/admin-user-payments/admin-user-payments.module'
                )
            ).AdminUserPaymentsModule,
        canActivate: [AuthenticationGuard],
    },
    {
        path: '**',
        loadChildren: async () =>
            (await import('./feature/catchers/not-found/not-found.module'))
                .NotFoundModule,
        canActivate: [GuestOrAuthenticatedGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
        StoreRouterConnectingModule.forRoot(),
    ],
    exports: [RouterModule],
    providers: [AuthenticationGuard, GuestGuard, GuestOrAuthenticatedGuard],
})
export class AppRoutingModule {}
