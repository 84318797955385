import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AppState } from '@state/app.state';
import { selectAuthenticated } from '@selector/authentication/authentication.selectors';
import { Injectable } from '@angular/core';
import {
    AuthenticationActionsEnum,
    checkAuthenticationAction,
} from '@action/authentication/authentication.actions';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(
        private readonly store: Store<AppState>,
        private readonly router: Router,
        private readonly actions$: Actions,
    ) {}

    /**
     * Can activate route protected by authentication.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        const authenticationChecked = await firstValueFrom(
            this.store.select(
                (storeState: AppState) =>
                    storeState.authenticationState.authenticationChecked,
            ),
        );

        if (!authenticationChecked) {
            this.store.dispatch(checkAuthenticationAction());

            await firstValueFrom(
                this.actions$.pipe(
                    ofType(
                        AuthenticationActionsEnum.CheckAuthenticationSuccess,
                        AuthenticationActionsEnum.CheckAuthenticationFailure,
                    ),
                ),
            );
        }

        const authenticated = await firstValueFrom(
            this.store.select(selectAuthenticated),
        );

        return (
            authenticated ||
            this.router.createUrlTree(['/', 'login'], {
                queryParams: {
                    returnUrl: state.url,
                },
            })
        );
    }
}
