<div class="backdrop">
    <div class="modal">
        <h1 class="modal__title">
            {{ 'shared.update_modal.title' | translate }}
        </h1>

        <p class="modal__description">
            {{ 'shared.update_modal.description' | translate }}
        </p>

        <button class="modal__button" (click)="confirmAction()">
            {{ 'shared.update_modal.confirm' | translate }}
        </button>

        <button
            class="modal__button modal__button--secondary"
            (click)="cancelAction()"
        >
            {{ 'shared.update_modal.cancel' | translate }}
        </button>
    </div>
</div>
