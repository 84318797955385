import { createReducer, Action, on } from '@ngrx/store';
import { User } from '@utility/user/user';
import {
    resetUserAction,
    storeUserAction,
    storeUserFailureAction,
    storeUserSuccessAction,
    updateUserAction,
    updateUserFailureAction,
    updateUserSuccessAction,
    cancelSubscriptionAction,
    cancelSubscriptionFailureAction,
    cancelSubscriptionSuccessAction,
    startSubscriptionAction,
    startSubscriptionFailureAction,
    startSubscriptionSuccessAction,
    activateUserAction,
    activateUserFailureAction,
    activateUserSuccessAction,
    updateUserBillingSuccessAction,
    updateUserBillingFailureAction,
    resetUserIsSuccessfulAction,
} from '@action/users/user.actions';
import { getInitialItemState, ItemState } from '@state/item.state';
import { ResponseResource } from '@utility/http/response-resource';
import {
    baseStoreItemReducer,
    baseResetItemReducer,
    baseStoreItemSuccessReducer,
    baseStoreItemFailureReducer,
    baseUpdateItemReducer,
    baseUpdateItemSuccessReducer,
    baseUpdateItemFailureReducer,
} from '../base/base-item.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionPayment } from '@utility/subscription/subscription-payment';
import { updateUserBillingAction } from '../../actions/users/user.actions';

export const userReducer = (itemState: ItemState<User>, action: Action) =>
    createReducer(
        getInitialItemState<User>(),
        on(storeUserAction, (state: ItemState<User>) =>
            baseStoreItemReducer<User>(state),
        ),
        on(
            storeUserSuccessAction,
            (state: ItemState<User>, response: ResponseResource<User>) =>
                baseStoreItemSuccessReducer<User>(state, response),
        ),
        on(
            storeUserFailureAction,
            (state: ItemState<User>, response: { error: HttpErrorResponse }) =>
                baseStoreItemFailureReducer<User>(state, response.error),
        ),

        on(updateUserAction, (state: ItemState<User>) =>
            baseUpdateItemReducer<User>(state),
        ),
        on(
            updateUserSuccessAction,
            (state: ItemState<User>, response: ResponseResource<User>) =>
                baseUpdateItemSuccessReducer<User>(state, response),
        ),
        on(
            updateUserFailureAction,
            (state: ItemState<User>, response: { error: HttpErrorResponse }) =>
                baseUpdateItemFailureReducer<User>(state, response.error),
        ),

        on(cancelSubscriptionAction, (state: ItemState<User>) =>
            baseUpdateItemReducer<User>(state),
        ),
        on(cancelSubscriptionSuccessAction, (state: ItemState<User>) => ({
            ...state,
            loading: false,
            isSuccessful: true,
        })),
        on(
            cancelSubscriptionFailureAction,
            (state: ItemState<User>, response: { error: HttpErrorResponse }) =>
                baseUpdateItemFailureReducer<User>(state, response.error),
        ),

        on(startSubscriptionAction, (state: ItemState<User>) =>
            baseUpdateItemReducer<User>(state),
        ),
        on(
            startSubscriptionSuccessAction,
            (
                state: ItemState<User>,
                response: ResponseResource<SubscriptionPayment>,
            ) => ({
                ...state,
                loading: false,
                isSuccessful: true,
                data: {
                    ...(state.data ?? ({} as User)),
                    redirectUrl: response.data.redirectUrl,
                },
            }),
        ),
        on(
            startSubscriptionFailureAction,
            (state: ItemState<User>, response: { error: HttpErrorResponse }) =>
                baseUpdateItemFailureReducer<User>(state, response.error),
        ),

        on(activateUserAction, (state: ItemState<User>) =>
            baseUpdateItemReducer<User>(state),
        ),
        on(
            activateUserSuccessAction,
            (state: ItemState<User>, response: ResponseResource<User>) =>
                baseUpdateItemSuccessReducer(state, response),
        ),
        on(
            activateUserFailureAction,
            (state: ItemState<User>, response: { error: HttpErrorResponse }) =>
                baseUpdateItemFailureReducer<User>(state, response.error),
        ),

        on(updateUserBillingAction, (state: ItemState<User>) =>
            baseUpdateItemReducer<User>(state),
        ),
        on(
            updateUserBillingSuccessAction,
            (state: ItemState<User>, response: ResponseResource<User>) =>
                baseUpdateItemSuccessReducer(state, response),
        ),
        on(
            updateUserBillingFailureAction,
            (state: ItemState<User>, response: { error: HttpErrorResponse }) =>
                baseUpdateItemFailureReducer<User>(state, response.error),
        ),

        on(resetUserAction, () => baseResetItemReducer<User>()),
        on(resetUserIsSuccessfulAction, (state: ItemState<User>) => ({
            ...state,
            isSuccessful: false,
        })),
    )(itemState, action);
