import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Activity } from '@utility/activity/activity';
import { StoreActivityRequestProps } from '@utility/activity/store-activity-request';

export enum ActivityActionsEnum {
    STORE_ACTIVITY = '[ACTIVITY] Store activity',
    STORE_ACTIVITY_SUCCESS = '[ACTIVITY] Store activity success',
    STORE_ACTIVITY_FAILURE = '[ACTIVITY] Store activity failure',

    RESET_ACTIVITY = '[ACTIVITY] Reset activity',
}

export const storeActivityAction = createAction(
    ActivityActionsEnum.STORE_ACTIVITY,
    props<StoreActivityRequestProps>(),
);
export const storeActivitySuccessAction = createAction(
    ActivityActionsEnum.STORE_ACTIVITY_SUCCESS,
    props<ResponseResource<Activity>>(),
);
export const storeActivityFailureAction = createAction(
    ActivityActionsEnum.STORE_ACTIVITY_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetActivityAction = createAction(
    ActivityActionsEnum.RESET_ACTIVITY,
);
