import { Injectable } from '@angular/core';
import { ApiService } from '@utility/http/api.service';
import { Params } from '@angular/router';
import { UserPasswordResetRequest } from './user-password-reset-request';
import { StoreUserPasswordResetRequest } from './store-user-password-reset-request';
import { UpdateUserPasswordResetRequest } from './update-user-password-reset-request';

@Injectable({ providedIn: 'root' })
export class UserPasswordResetRequestService extends ApiService<
    UserPasswordResetRequest,
    Params,
    Params,
    StoreUserPasswordResetRequest,
    UpdateUserPasswordResetRequest
> {
    protected baseUrl = 'user-password-reset-requests';
}
