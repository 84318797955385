import { Injectable } from '@angular/core';
import { ApiService } from '@utility/http/api.service';
import { Params } from '@angular/router';
import { User } from '@utility/user/user';
import { StoreUserRequest } from '@utility/user/store-user-request';
import { UpdateUserRequest } from './update-user-request';
import { Observable } from 'rxjs';
import { ResponseResource } from '@utility/http/response-resource';
import { ActivateUserRequest } from './activate-user-request';
import { UpdateBillingRequest } from './update-billing-request';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService<
    User,
    Params,
    Params,
    StoreUserRequest,
    UpdateUserRequest
> {
    protected baseUrl = 'users';

    /**
     * Activate user request.
     *
     * @param payload
     *
     * @returns Observable of user response.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public activate(
        payload: ActivateUserRequest,
    ): Observable<ResponseResource<User>> {
        return this.httpService.put(`${this.baseUrl}/activate`, payload);
    }

    /**
     * Activate user request.
     *
     * @param payload
     *
     * @returns Observable of user response.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updateBilling(
        payload: UpdateBillingRequest,
    ): Observable<ResponseResource<User>> {
        return this.httpService.put(`${this.baseUrl}/billing`, payload);
    }
}
