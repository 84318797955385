import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '@utility/http/api.service';
import { Playlist } from '@utility/playlist/playlist';
import { PlaylistsIndexRequest } from './playlists-index-request';
import { StorePlaylistRequest } from './store-playlist-request';
import { UpdatePLaylistRequest } from './update-playlist-request';

@Injectable({ providedIn: 'root' })
export class PlaylistService extends ApiService<
    Playlist,
    PlaylistsIndexRequest,
    Params,
    StorePlaylistRequest,
    UpdatePLaylistRequest
> {
    protected baseUrl = 'playlists';
}
