import { createReducer, Action, on } from '@ngrx/store';
import {
    storeUserPasswordResetRequestAction,
    storeUserPasswordResetRequestSuccessAction,
    storeUserPasswordResetRequestFailureAction,
    updateUserPasswordResetRequestAction,
    updateUserPasswordResetRequestSuccessAction,
    updateUserPasswordResetRequestFailureAction,
    resetUserPasswordResetRequestAction,
} from '@action/user-password-reset-request/user-password-reset-request.actions';
import { getInitialItemState, ItemState } from '@state/item.state';
import { ResponseResource } from '@utility/http/response-resource';
import {
    baseStoreItemReducer,
    baseResetItemReducer,
    baseStoreItemSuccessReducer,
    baseStoreItemFailureReducer,
    baseUpdateItemReducer,
    baseUpdateItemSuccessReducer,
    baseUpdateItemFailureReducer,
} from '../base/base-item.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPasswordResetRequest } from '@utility/user-password-reset-request/user-password-reset-request';

export const userPasswordResetRequestReducer = (
    itemState: ItemState<UserPasswordResetRequest>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<UserPasswordResetRequest>(),
        on(
            storeUserPasswordResetRequestAction,
            (state: ItemState<UserPasswordResetRequest>) =>
                baseStoreItemReducer<UserPasswordResetRequest>(state),
        ),
        on(
            storeUserPasswordResetRequestSuccessAction,
            (
                state: ItemState<UserPasswordResetRequest>,
                response: ResponseResource<UserPasswordResetRequest>,
            ) =>
                baseStoreItemSuccessReducer<UserPasswordResetRequest>(
                    state,
                    response,
                ),
        ),
        on(
            storeUserPasswordResetRequestFailureAction,
            (
                state: ItemState<UserPasswordResetRequest>,
                response: { error: HttpErrorResponse },
            ) =>
                baseStoreItemFailureReducer<UserPasswordResetRequest>(
                    state,
                    response.error,
                ),
        ),

        on(
            updateUserPasswordResetRequestAction,
            (state: ItemState<UserPasswordResetRequest>) =>
                baseUpdateItemReducer<UserPasswordResetRequest>(state),
        ),
        on(
            updateUserPasswordResetRequestSuccessAction,
            (
                state: ItemState<UserPasswordResetRequest>,
                response: ResponseResource<UserPasswordResetRequest>,
            ) =>
                baseUpdateItemSuccessReducer<UserPasswordResetRequest>(
                    state,
                    response,
                ),
        ),
        on(
            updateUserPasswordResetRequestFailureAction,
            (
                state: ItemState<UserPasswordResetRequest>,
                response: { error: HttpErrorResponse },
            ) =>
                baseUpdateItemFailureReducer<UserPasswordResetRequest>(
                    state,
                    response.error,
                ),
        ),

        on(resetUserPasswordResetRequestAction, () =>
            baseResetItemReducer<UserPasswordResetRequest>(),
        ),
    )(itemState, action);
