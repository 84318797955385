import {
    loadSetsAction,
    loadSetsFailureAction,
    loadSetsSuccessAction,
    resetSetsAction,
} from '@action/sets/sets.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { Set } from '@utility/sets/set';

export const setsReducer = (listState: ListState<Set>, action: Action) =>
    createReducer(
        getInitialListState<Set>(),
        on(loadSetsAction, state => baseLoadListReducer(state)),
        on(loadSetsSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(loadSetsFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetSetsAction, () => baseResetListReducer<Set>()),
    )(listState, action);
