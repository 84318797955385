import { createAction, props } from '@ngrx/store';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { MessagesIndexRequestProps } from '@utility/message/messages-index-request';
import { Message } from '@utility/message/message';

export enum MessagesActionsEnum {
    LOAD_MESSAGES = '[MESSAGES] Load messages',
    LOAD_MESSAGES_SUCCESS = '[MESSAGES] Load messages success',
    LOAD_MESSAGES_FAILURE = '[MESSAGES] Load messages failure',

    RESET_MESSAGES = '[MESSAGES] Reset messages',
}

export const loadMessagesAction = createAction(
    MessagesActionsEnum.LOAD_MESSAGES,
    props<MessagesIndexRequestProps>(),
);

export const loadMessagesSuccessAction = createAction(
    MessagesActionsEnum.LOAD_MESSAGES_SUCCESS,
    props<ResponseResourceCollection<Message>>(),
);

export const loadMessagesFailureAction = createAction(
    MessagesActionsEnum.LOAD_MESSAGES_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetMessagesAction = createAction(
    MessagesActionsEnum.RESET_MESSAGES,
);
