import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColorEnum } from '@enum/colors.enum';

@Injectable({ providedIn: 'root' })
export class HeaderService {
    private readonly titleSubject$: BehaviorSubject<string>;
    public readonly title$: Observable<string>;
    private readonly colorSubject$: BehaviorSubject<string>;
    public color$: Observable<string>;
    private readonly textColorSubject$: BehaviorSubject<string>;
    public textColor$: Observable<string>;
    private readonly subTitleSubject$: BehaviorSubject<string>;
    public readonly subTitle$: Observable<string>;
    private readonly shadowlessSubject$: BehaviorSubject<boolean>;
    public readonly shadowless$: Observable<boolean>;
    private readonly hideMenuButtonSubject$: BehaviorSubject<boolean>;
    public readonly hideMenuButton$: Observable<boolean>;

    constructor() {
        this.titleSubject$ = new BehaviorSubject(null);
        this.title$ = this.titleSubject$.asObservable();

        this.subTitleSubject$ = new BehaviorSubject(null);
        this.subTitle$ = this.subTitleSubject$.asObservable();

        this.colorSubject$ = new BehaviorSubject(null);
        this.color$ = this.colorSubject$.asObservable();

        this.textColorSubject$ = new BehaviorSubject(null);
        this.textColor$ = this.textColorSubject$.asObservable();

        this.shadowlessSubject$ = new BehaviorSubject(false);
        this.shadowless$ = this.shadowlessSubject$.asObservable();

        this.hideMenuButtonSubject$ = new BehaviorSubject(false);
        this.hideMenuButton$ = this.hideMenuButtonSubject$.asObservable();
    }

    /**
     * Set title of header.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public setTitle(title: string): void {
        this.titleSubject$.next(title);
    }

    /**
     * Set sub title of header.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public setSubTitle(title: string): void {
        this.subTitleSubject$.next(title);
    }

    /**
     * Set color of header.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public setColor(color: string): void {
        this.colorSubject$.next(color);

        if (color === ColorEnum.White) {
            this.textColorSubject$.next(ColorEnum.Black);
        } else {
            this.textColorSubject$.next(ColorEnum.White);
        }
    }

    /**
     * Set shadowless.
     *
     * @param value
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public setShadowless(value: boolean): void {
        this.shadowlessSubject$.next(value);
    }

    /**
     * Set hide menu button.
     *
     * @param value
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public setHideMenuButton(value: boolean): void {
        this.hideMenuButtonSubject$.next(value);
    }
}
