import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { UserPayment } from '@utility/user-payment/user-payment';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { IndexUserPaymentsProps } from '@utility/user-payment/index-user-payments-request';

export enum UserPaymentsActionsEnum {
    IndexUserPayments = '[USER PAYMENTS] Index user payments',
    IndexUserPaymentsSuccess = '[USER PAYMENTS] Index user payments success',
    IndexUserPaymentsFailure = '[USER PAYMENTS] Index user payments failure',

    ResetUserPayments = '[USER PAYMENTS] Reset user payments',
}

export const indexUserPaymentsAction = createAction(
    UserPaymentsActionsEnum.IndexUserPayments,
    props<IndexUserPaymentsProps>(),
);

export const indexUserPaymentsSuccessAction = createAction(
    UserPaymentsActionsEnum.IndexUserPaymentsSuccess,
    props<ResponseResourceCollection<UserPayment>>(),
);

export const indexUserPaymentsFailureAction = createAction(
    UserPaymentsActionsEnum.IndexUserPaymentsFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetUserPaymentsAction = createAction(
    UserPaymentsActionsEnum.ResetUserPayments,
);
