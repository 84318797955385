import { HttpErrorResponse } from '@angular/common/http';
import { ItemState, getInitialItemState } from '@state/item.state';
import { ResponseResource } from '@utility/http/response-resource';

export const baseShowItemReducer = <T>(state: ItemState<T>): ItemState<T> => ({
    ...state,
    loading: true,
});

export const baseShowItemSuccessReducer = <T>(
    state: ItemState<T>,
    response: ResponseResource<T>,
): ItemState<T> => ({
    ...state,
    data: response.data,
    loading: false,
});

export const baseShowItemFailureReducer = <T>(
    state: ItemState<T>,
    error: HttpErrorResponse,
): ItemState<T> => ({
    ...state,
    loading: false,
    error,
    errorResource: error.error,
});

export const baseStoreItemReducer = <T>(state: ItemState<T>): ItemState<T> => ({
    ...state,
    loading: true,
});

export const baseStoreItemSuccessReducer = <T>(
    state: ItemState<T>,
    response: ResponseResource<T>,
): ItemState<T> => ({
    ...state,
    data: response.data,
    loading: false,
    isSuccessful: true,
    error: null,
    errorResource: null,
});

export const baseStoreItemFailureReducer = <T>(
    state: ItemState<T>,
    error: HttpErrorResponse,
): ItemState<T> => ({
    ...state,
    loading: false,
    error,
    errorResource: error.error,
    isSuccessful: false,
});

export const baseUpdateItemReducer = <T>(
    state: ItemState<T>,
): ItemState<T> => ({
    ...state,
    loading: true,
    isSuccessful: false,
});

export const baseUpdateItemSuccessReducer = <T>(
    state: ItemState<T>,
    response: ResponseResource<T>,
): ItemState<T> => ({
    ...state,
    data: response.data,
    loading: false,
    isSuccessful: true,
    error: null,
    errorResource: null,
});

export const baseUpdateItemFailureReducer = <T>(
    state: ItemState<T>,
    error: HttpErrorResponse,
): ItemState<T> => ({
    ...state,
    loading: false,
    error,
    errorResource: error.error,
    isSuccessful: false,
});

export const baseDeleteItemReducer = <T>(
    state: ItemState<T>,
): ItemState<T> => ({
    ...state,
    loading: true,
    isSuccessful: false,
});

export const baseDeleteItemSuccessReducer = <T>(
    state: ItemState<T>,
): ItemState<T> => ({
    ...state,
    data: null,
    loading: false,
    isSuccessful: true,
});

export const baseDeleteItemFailureReducer = <T>(
    state: ItemState<T>,
    error: HttpErrorResponse,
): ItemState<T> => ({
    ...state,
    loading: false,
    error,
    errorResource: error.error,
    isSuccessful: false,
});

export const baseResetItemReducer = <T>(): ItemState<T> => ({
    ...getInitialItemState<T>(),
});
