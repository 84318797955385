import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScrollService } from '@utility/scroll/scroll.service';

@Injectable({ providedIn: 'root' })
export class UpdateService {
    private readonly updateAvailableSubject$: BehaviorSubject<boolean>;
    public updateAvailable$: Observable<boolean>;

    constructor(
        private readonly swUpdate: SwUpdate,
        private readonly scrollService: ScrollService,
    ) {
        this.updateAvailableSubject$ = new BehaviorSubject<boolean>(false);
        this.updateAvailable$ = this.updateAvailableSubject$.asObservable();
    }

    /**
     * Listen for updates.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public listenForUpdates(): void {
        if (!this.swUpdate.isEnabled) {
            return;
        }

        this.swUpdate.versionUpdates
            .pipe(
                filter(
                    (event): event is VersionReadyEvent =>
                        event.type === 'VERSION_READY',
                ),
            )
            .subscribe(() => {
                this.updateAvailableSubject$.next(true);
                this.scrollService.disable();
            });
    }

    /**
     * Reload application to apply update.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public reload(): void {
        window.location.reload();
    }

    /**
     * Cancel update event.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public cancelUpdate(): void {
        this.updateAvailableSubject$.next(false);
        this.scrollService.enable();
    }
}
