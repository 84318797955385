import { createAction, props } from '@ngrx/store';
import { PlaylistsIndexRequestProps } from '@utility/playlist/playlists-index-request';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { Playlist } from '@utility/playlist/playlist';
import { HttpErrorResponse } from '@angular/common/http';

export enum PlaylistsActionsEnum {
    LOAD_PLAYLISTS = '[PLAYLISTS] Load playlists',
    LOAD_PLAYLISTS_SUCCESS = '[PLAYLISTS] Load playlists success',
    LOAD_PLAYLISTS_FAILURE = '[PLAYLISTS] Load playlists failure',

    RESET_PLAYLISTS = '[PLAYLISTS] Reset playlists',
}

export const loadPlaylistsAction = createAction(
    PlaylistsActionsEnum.LOAD_PLAYLISTS,
    props<PlaylistsIndexRequestProps>(),
);
export const loadPlaylistsSuccessAction = createAction(
    PlaylistsActionsEnum.LOAD_PLAYLISTS_SUCCESS,
    props<ResponseResourceCollection<Playlist>>(),
);
export const loadPlaylistsFailureAction = createAction(
    PlaylistsActionsEnum.LOAD_PLAYLISTS_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetPlaylistsAction = createAction(
    PlaylistsActionsEnum.RESET_PLAYLISTS,
);
