import { createAction, props } from '@ngrx/store';
import { Playlist } from '@utility/playlist/playlist';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { StorePlaylistProps } from '@utility/playlist/store-playlist-request';
import { UpdatePLaylistProps } from '@utility/playlist/update-playlist-request';

export enum PlaylistActionsEnum {
    SHOW_PLAYLIST = '[PLAYLIST] Show playlist',
    SHOW_PLAYLIST_SUCCESS = '[PLAYLIST] Show playlist success',
    SHOW_PLAYLIST_FAILURE = '[PLAYLIST] Show playlist failure',

    STORE_PLAYLIST = '[PLAYLIST] Store playlist',
    STORE_PLAYLIST_SUCCESS = '[PLAYLIST] Store playlist success',
    STORE_PLAYLIST_FAILURE = '[PLAYLIST] Store playlist failure',

    UPDATE_PLAYLIST = '[PLAYLIST] Update playlist',
    UPDATE_PLAYLIST_SUCCESS = '[PLAYLIST] Update playlist success',
    UPDATE_PLAYLIST_FAILURE = '[PLAYLIST] Update playlist failure',

    DELETE_PLAYLIST = '[PLAYLIST] Delete playlist',
    DELETE_PLAYLIST_SUCCESS = '[PLAYLIST] Delete playlist success',
    DELETE_PLAYLIST_FAILURE = '[PLAYLIST] Delete playlist failure',

    RESET_PLAYLIST = '[PLAYLIST] Reset playlist',
}

export const showPlaylistAction = createAction(
    PlaylistActionsEnum.SHOW_PLAYLIST,
    props<{ id: number }>(),
);
export const showPlaylistSuccessAction = createAction(
    PlaylistActionsEnum.SHOW_PLAYLIST_SUCCESS,
    props<ResponseResource<Playlist>>(),
);
export const showPlaylistFailureAction = createAction(
    PlaylistActionsEnum.SHOW_PLAYLIST_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const storePlaylistAction = createAction(
    PlaylistActionsEnum.STORE_PLAYLIST,
    props<StorePlaylistProps>(),
);
export const storePlaylistSuccessAction = createAction(
    PlaylistActionsEnum.STORE_PLAYLIST_SUCCESS,
    props<ResponseResource<Playlist>>(),
);
export const storePlaylistFailureAction = createAction(
    PlaylistActionsEnum.STORE_PLAYLIST_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const updatePlaylistAction = createAction(
    PlaylistActionsEnum.UPDATE_PLAYLIST,
    props<UpdatePLaylistProps>(),
);
export const updatePlaylistSuccessAction = createAction(
    PlaylistActionsEnum.UPDATE_PLAYLIST_SUCCESS,
    props<ResponseResource<Playlist>>(),
);
export const updatePlaylistFailureAction = createAction(
    PlaylistActionsEnum.UPDATE_PLAYLIST_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const deletePlaylistAction = createAction(
    PlaylistActionsEnum.DELETE_PLAYLIST,
    props<{ id: number }>(),
);
export const deletePlaylistSuccessAction = createAction(
    PlaylistActionsEnum.DELETE_PLAYLIST_SUCCESS,
);
export const deletePlaylistFailureAction = createAction(
    PlaylistActionsEnum.DELETE_PLAYLIST_FAILURE,
    props<{ error: HttpErrorResponse }>(),
);

export const resetPlaylistAction = createAction(
    PlaylistActionsEnum.RESET_PLAYLIST,
);
