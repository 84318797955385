import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { rootState } from '@state/root.state';
import { CoreTranslateModule } from './translate.module';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from '@effect/authentication/authentication.effects';
import { httpInterceptorProvider } from '@utility/http/interceptors/http-interceptors-provider';
import { SessionEffects } from '@effect/session/session.effects';
import { MessagesCountEffects } from '@effect/messages/messages-count.effects';
import { activitiesReducer } from '@reducer/activities/activities.reducer';
import { activityReducer } from '@reducer/activities/activity.reducer';
import { ActivitiesEffects } from '@effect/activities/activities.effects';
import { workshopsReducer } from '@reducer/workshops/workshops.reducer';
import { setReducer } from '@reducer/sets/set.reducer';
import { setsReducer } from '@reducer/sets/sets.reducer';
import { quoteReducer } from '@reducer/quotes/quote.reducer';
import { QuoteEffects } from '@effect/quotes/quote.effects';
import { SetsEffects } from '@effect/sets/sets.effects';
import { WorkshopsEffects } from '@effect/workshops/workshops.effects';
import { UserEffects } from '@effect/users/user.effects';
import { userReducer } from '@reducer/users/user.reducer';
import { messageReducer } from '@reducer/messages/message.reducer';
import { messagesReducer } from '@reducer/messages/messages.reducer';
import { MessagesEffects } from '@effect/messages/messages.effects';
import { MessageEffects } from '@effect/messages/message.effects';
import { MoveEffects } from '@effect/moves/move.effects';
import { movesReducer } from '@reducer/moves/moves.reducer';
import { moveReducer } from '@reducer/moves/move.reducer';
import { playlistReducer } from '@reducer/playlists/playlist.reducer';
import { playlistsReducer } from '@reducer/playlists/playlists.reducer';
import { PlaylistEffects } from '@effect/playlists/playlist.effects';
import { PlaylistsEffects } from '@effect/playlists/playlists.effects';
import { SetEffects } from '@effect/sets/set.effects';
import { workshopReducer } from '@reducer/workshops/workshop.reducer';
import { WorkshopEffects } from '@effect/workshops/workshop.effects';
import { MovesEffects } from '@effect/moves/moves.effects';
import { ActivityEffects } from '@effect/activities/activity.effects';
import { userPasswordResetRequestReducer } from '@reducer/user-password-reset-request/user-password-reset-request.reducer';
import { UserPasswordResetRequestEffects } from '@effect/user-password-reset-request/user-password-reset-request.effects';
import { CouponEffects } from '@effect/coupons/coupon.effects';
import { couponReducer } from '@reducer/coupons/coupon.reducer';
import { WorkshopEnlistmentEffects } from '@effect/workshop-enlistments/workshop-enlistment.effects';
import { WorkshopsEnlistmentsEffects } from '@effect/workshop-enlistments/workshop-enlistments.effects';
import { workshopEnlistmentsReducer } from '@reducer/workshop-enlistments/workshop-enlistments.reducer';
import { workshopEnlistmentReducer } from '@reducer/workshop-enlistments/workshop-enlistment.reducer';
import { workshopDatesReducer } from '@reducer/workshop-dates/workshop-dates.reducer';
import { workshopDateReducer } from '@reducer/workshop-dates/workshop-date.reducer';
import { WorkshopDateEffects } from '@effect/workshop-dates/workshop-date.effects';
import { WorkshopDatesEffects } from '@effect/workshop-dates/workshop-dates.effects';
import { UserPaymentsEffects } from '@effect/user-payments/user-payments.effects';
import { userPaymentsReducer } from '@reducer/user-payments/user-payments.reducer';
import { UserPaymentEffects } from '@effect/user-payments/user-payment.effects';
import { userPaymentReducer } from '@reducer/user-payments/user-payment.reducer';

@NgModule({
    imports: [
        CoreTranslateModule,
        EffectsModule.forRoot([
            AuthenticationEffects,

            SessionEffects,

            MessagesCountEffects,

            ActivitiesEffects,
            ActivityEffects,

            QuoteEffects,

            SetsEffects,
            SetEffects,

            WorkshopsEffects,
            WorkshopEffects,

            WorkshopEnlistmentEffects,
            WorkshopsEnlistmentsEffects,

            WorkshopDateEffects,
            WorkshopDatesEffects,

            UserEffects,

            MessageEffects,
            MessagesEffects,

            PlaylistEffects,
            PlaylistsEffects,

            MoveEffects,
            MovesEffects,

            UserPasswordResetRequestEffects,

            CouponEffects,

            UserPaymentsEffects,
            UserPaymentEffects,
        ]),
        StoreModule.forRoot(rootState),
        StoreModule.forFeature('activitiesState', {
            activitiesState: activitiesReducer,
            activityState: activityReducer,
        }),
        StoreModule.forFeature('dashboardState', {
            quoteState: quoteReducer,
        }),
        StoreModule.forFeature('setsState', {
            setsState: setsReducer,
            setState: setReducer,
        }),
        StoreModule.forFeature('workshopsState', {
            workshopsState: workshopsReducer,
            workshopState: workshopReducer,
        }),
        StoreModule.forFeature('workshopEnlistmentsState', {
            workshopEnlistmentsState: workshopEnlistmentsReducer,
            workshopEnlistmentState: workshopEnlistmentReducer,
        }),
        StoreModule.forFeature('workshopDatesState', {
            workshopDatesState: workshopDatesReducer,
            workshopDateState: workshopDateReducer,
        }),
        StoreModule.forFeature('usersState', {
            userState: userReducer,
        }),
        StoreModule.forFeature('messagesState', {
            messageState: messageReducer,
            messagesState: messagesReducer,
        }),
        StoreModule.forFeature('movesState', {
            movesState: movesReducer,
            moveState: moveReducer,
        }),
        StoreModule.forFeature('playlistsState', {
            playlistState: playlistReducer,
            playlistsState: playlistsReducer,
        }),
        StoreModule.forFeature('userPasswordResetRequestState', {
            userPasswordResetRequestState: userPasswordResetRequestReducer,
        }),
        StoreModule.forFeature('couponsState', {
            couponState: couponReducer,
        }),
        StoreModule.forFeature('userPaymentsState', {
            userPaymentsState: userPaymentsReducer,
            userPaymentState: userPaymentReducer,
        }),
    ],
    providers: [httpInterceptorProvider],
})
export class CoreModule {}
