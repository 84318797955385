import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from '@utility/message/message.service';
import {
    loadMessagesCountFailureAction,
    loadMessagesCountSuccessAction,
    MessagesCountActionsEnum,
} from '@action/messages/messages-count.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { UnreadMessagesCount } from '@utility/message/unread-messages-count';

@Injectable()
export class MessagesCountEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly messageService: MessageService,
    ) {}

    /**
     * Create messages effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public loadMessagesCountEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesCountActionsEnum.LOAD_MESSAGES_COUNT),
            mergeMap(() =>
                this.messageService.count().pipe(
                    map((response: ResponseResource<UnreadMessagesCount>) =>
                        loadMessagesCountSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadMessagesCountFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
