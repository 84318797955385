import {
    showPlaylistAction,
    showPlaylistFailureAction,
    showPlaylistSuccessAction,
    storePlaylistAction,
    storePlaylistSuccessAction,
    storePlaylistFailureAction,
    updatePlaylistAction,
    updatePlaylistSuccessAction,
    updatePlaylistFailureAction,
    resetPlaylistAction,
    deletePlaylistAction,
    deletePlaylistSuccessAction,
    deletePlaylistFailureAction,
} from '@action/playlists/playlist.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
    baseStoreItemFailureReducer,
    baseStoreItemReducer,
    baseStoreItemSuccessReducer,
    baseUpdateItemFailureReducer,
    baseUpdateItemReducer,
    baseUpdateItemSuccessReducer,
    baseDeleteItemFailureReducer,
    baseDeleteItemReducer,
    baseDeleteItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Playlist } from '@utility/playlist/playlist';

export const playlistReducer = (
    itemState: ItemState<Playlist>,
    action: Action,
) =>
    createReducer(
        getInitialItemState<Playlist>(),
        on(showPlaylistAction, state => baseShowItemReducer(state)),
        on(showPlaylistSuccessAction, (state, response) =>
            baseShowItemSuccessReducer<Playlist>(state, response),
        ),
        on(showPlaylistFailureAction, (state, response) =>
            baseShowItemFailureReducer<Playlist>(state, response.error),
        ),

        on(storePlaylistAction, state => baseStoreItemReducer(state)),
        on(storePlaylistSuccessAction, (state, response) =>
            baseStoreItemSuccessReducer<Playlist>(state, response),
        ),
        on(storePlaylistFailureAction, (state, response) =>
            baseStoreItemFailureReducer<Playlist>(state, response.error),
        ),

        on(updatePlaylistAction, state => baseUpdateItemReducer(state)),
        on(updatePlaylistSuccessAction, (state, response) =>
            baseUpdateItemSuccessReducer<Playlist>(state, response),
        ),
        on(updatePlaylistFailureAction, (state, response) =>
            baseUpdateItemFailureReducer<Playlist>(state, response.error),
        ),

        on(deletePlaylistAction, state => baseDeleteItemReducer(state)),
        on(deletePlaylistSuccessAction, state =>
            baseDeleteItemSuccessReducer<Playlist>(state),
        ),
        on(deletePlaylistFailureAction, (state, response) =>
            baseDeleteItemFailureReducer<Playlist>(state, response.error),
        ),

        on(resetPlaylistAction, () => baseResetItemReducer<Playlist>()),
    )(itemState, action);
