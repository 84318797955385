import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkshopEnlistmentService } from '@utility/workshop-enlistment/workshop-enlistment.service';
import { WorkshopEnlistment } from '@utility/workshop-enlistment/workshop-enlistment';
import { ResponseResource } from '@utility/http/response-resource';
import {
    storeWorkshopEnlistmentFailureAction,
    storeWorkshopEnlistmentSuccessAction,
    WorkshopEnlistmentActionsEnum,
} from '@action/workshop-enlistments/workshop-enlistment.actions';
import { StoreWorkshopEnlistmentProps } from '@utility/workshop-enlistment/store-workshop-enlistment-request';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { incrementEnlistmentCountWorkshopAction } from '@action/workshops/workshop.actions';

@Injectable({ providedIn: 'root' })
export class WorkshopEnlistmentEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly workshopEnlistmentService: WorkshopEnlistmentService,
        private readonly store: Store<AppState>,
    ) {}

    /**
     * Create store workshop enlistment effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public storeWorkshopEnlistmentEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WorkshopEnlistmentActionsEnum.STORE_WORKSHOP_ENLISTMENT),
            mergeMap((props: StoreWorkshopEnlistmentProps) =>
                this.workshopEnlistmentService.store(props.payload).pipe(
                    map((response: ResponseResource<WorkshopEnlistment>) => {
                        (async () => {
                            this.store.dispatch(
                                incrementEnlistmentCountWorkshopAction({
                                    payload: {
                                        workshopDateId:
                                            props.payload.workshopDateId,
                                        userId: await firstValueFrom(
                                            this.store.select(
                                                (state: AppState) =>
                                                    state.authenticationState
                                                        .authenticatedUser.id,
                                            ),
                                        ),
                                        workshopEnlistment: response.data,
                                    },
                                }),
                            );
                        })();

                        return storeWorkshopEnlistmentSuccessAction(response);
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(storeWorkshopEnlistmentFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
