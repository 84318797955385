import { createAction, props } from '@ngrx/store';
import { MovesIndexRequestProps } from '@utility/move/moves-index-request';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseResource } from '@utility/http/response-resource';
import { Quote } from '@utility/quotes/quote';

export enum QuoteActionsEnum {
    ShowQuote = '[QUOTE] Show quote',
    ShowQuoteSuccess = '[QUOTE] Show quote success',
    ShowQuoteFailure = '[QUOTE] Show quote failure',

    ResetQuote = '[QUOTE] Reset quote',
}

export const showQuoteAction = createAction(QuoteActionsEnum.ShowQuote);

export const showQuoteSuccessAction = createAction(
    QuoteActionsEnum.ShowQuoteSuccess,
    props<ResponseResource<Quote>>(),
);

export const showQuoteFailureAction = createAction(
    QuoteActionsEnum.ShowQuoteFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetQuoteAction = createAction(QuoteActionsEnum.ResetQuote);
