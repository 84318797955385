import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserPaymentService } from '@utility/user-payment/user-payment.service';
import { UserPayment } from '@utility/user-payment/user-payment';
import {
    updateUserPaymentFailureAction,
    updateUserPaymentSuccessAction,
    UserPaymentActionsEnum,
} from '@action/user-payments/user-payment.actions';
import { UpdateUserPaymentProps } from '@utility/user-payment/update-user-payment-request';
import { ResponseResource } from '@utility/http/response-resource';

@Injectable({ providedIn: 'root' })
export class UserPaymentEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userPaymentService: UserPaymentService,
    ) {}

    /**
     * Create update user payments effects.
     *
     * @author Niek van der Velde <niek@aimtofeel.com>
     * @version 1.0.0
     */
    public updateUserPaymentEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPaymentActionsEnum.UpdateUserPayment),
            mergeMap((props: UpdateUserPaymentProps) =>
                this.userPaymentService
                    .update(props.payload, props.payload.id)
                    .pipe(
                        map((response: ResponseResource<UserPayment>) =>
                            updateUserPaymentSuccessAction(response),
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(updateUserPaymentFailureAction({ error })),
                        ),
                    ),
            ),
        ),
    );
}
