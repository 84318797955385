import {
    loadMovesAction,
    loadMovesFailureAction,
    loadMovesSuccessAction,
    resetMovesAction,
} from '@action/moves/moves.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
    baseLoadListFailureReducer,
    baseLoadListReducer,
    baseLoadListSuccessReducer,
    baseResetListReducer,
} from '@reducer/base/base-list.reducer';
import { getInitialListState, ListState } from '@state/list.state';
import { Move } from '@utility/move/move';

export const movesReducer = (listState: ListState<Move>, action: Action) =>
    createReducer(
        getInitialListState<Move>(),
        on(loadMovesAction, state => baseLoadListReducer(state)),
        on(loadMovesSuccessAction, (state, response) =>
            baseLoadListSuccessReducer(state, response),
        ),
        on(loadMovesFailureAction, (state, response) =>
            baseLoadListFailureReducer(state, response.error),
        ),
        on(resetMovesAction, () => baseResetListReducer<Move>()),
    )(listState, action);
