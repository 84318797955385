import {
    showMessageAction,
    showMessageFailureAction,
    showMessageSuccessAction,
    resetMessageAction,
} from '@action/messages/message.actions';
import { createReducer, Action, on } from '@ngrx/store';
import {
    baseResetItemReducer,
    baseShowItemFailureReducer,
    baseShowItemReducer,
    baseShowItemSuccessReducer,
} from '@reducer/base/base-item.reducer';
import { ItemState, getInitialItemState } from '@state/item.state';
import { Message } from '@utility/message/message';

export const messageReducer = (itemState: ItemState<Message>, action: Action) =>
    createReducer(
        getInitialItemState<Message>(),
        on(showMessageAction, state => baseShowItemReducer(state)),
        on(showMessageSuccessAction, (state, response) =>
            baseShowItemSuccessReducer<Message>(state, response),
        ),
        on(showMessageFailureAction, (state, response) =>
            baseShowItemFailureReducer<Message>(state, response.error),
        ),
        on(resetMessageAction, () => baseResetItemReducer<Message>()),
    )(itemState, action);
