import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlaylistService } from '@utility/playlist/playlist.service';
import {
    loadPlaylistsFailureAction,
    loadPlaylistsSuccessAction,
    PlaylistsActionsEnum,
} from '@action/playlists/playlists.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { PlaylistsIndexRequestProps } from '@utility/playlist/playlists-index-request';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { Playlist } from '@utility/playlist/playlist';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class PlaylistsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly playlistService: PlaylistService,
    ) {}

    public loadPlaylistsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlaylistsActionsEnum.LOAD_PLAYLISTS),
            mergeMap((props: PlaylistsIndexRequestProps) =>
                this.playlistService.index(props.payload).pipe(
                    map((response: ResponseResourceCollection<Playlist>) =>
                        loadPlaylistsSuccessAction(response),
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(loadPlaylistsFailureAction({ error })),
                    ),
                ),
            ),
        ),
    );
}
