import { createAction, props } from '@ngrx/store';
import { ResponseResourceCollection } from '@utility/http/response-resource-collection';
import { HttpErrorResponse } from '@angular/common/http';
import { Workshop } from '@utility/workshops/workshop';
import { WorkshopsIndexRequestProps } from '@utility/workshops/workshops-index-request';

export enum WorkshopsActionsEnum {
    LoadWorkshops = '[WORKSHOPS] Load workshops',
    LoadWorkshopsSuccess = '[WORKSHOPS] Load workshops success',
    LoadWorkshopsFailure = '[WORKSHOPS] Load workshops failure',

    ResetWorkshops = '[WORKSHOPS] Reset workshops',
}

export const loadWorkshopsAction = createAction(
    WorkshopsActionsEnum.LoadWorkshops,
    props<WorkshopsIndexRequestProps>(),
);

export const loadWorkshopsSuccessAction = createAction(
    WorkshopsActionsEnum.LoadWorkshopsSuccess,
    props<ResponseResourceCollection<Workshop>>(),
);

export const loadWorkshopsFailureAction = createAction(
    WorkshopsActionsEnum.LoadWorkshopsFailure,
    props<{ error: HttpErrorResponse }>(),
);

export const resetWorkshopsAction = createAction(
    WorkshopsActionsEnum.ResetWorkshops,
);
