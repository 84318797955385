import { createAction, props } from '@ngrx/store';
import { AlertProps } from '@state/alert.state';

export enum AlertActionsEnum {
    SET = '[ALERT] Set alert',
    REMOVE = '[ALERT] Remove alert',
    REMOVE_ALL = '[ALERT] Remove all alerts',
}

export const setAlertAction = createAction(
    AlertActionsEnum.SET,
    props<{ payload: AlertProps }>(),
);

export const removeAlertAction = createAction(
    AlertActionsEnum.REMOVE,
    props<{ key: string }>(),
);

export const removeAllAlertAction = createAction(AlertActionsEnum.REMOVE_ALL);
