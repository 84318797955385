import { routerReducer } from '@ngrx/router-store';
import { alertReducer } from '@reducer/alert/alert.reducer';
import { authenticationReducer } from '@reducer/authentication/authentication.reducer';
import { messagesCountReducer } from '@reducer/messages/messages-count.reducer';
import { sessionReducer } from '@reducer/session/session.reducer';

export const rootState = {
    router: routerReducer,

    authenticationState: authenticationReducer,

    sessionState: sessionReducer,

    alertState: alertReducer,

    messagesCountState: messagesCountReducer,
};
