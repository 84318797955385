import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { translationsLoaderFactory } from '@utility/translate/translations-loader.factory';
import { translationInitializeFactory } from '@utility/translate/translation-initialize.factory';

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translationsLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: translationInitializeFactory,
            deps: [TranslateService],
            multi: true,
        },
    ],
})
export class CoreTranslateModule {}
