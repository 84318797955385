// This file can be replaced during build by using the  array.
//  replaces  with .
// The list of file replacements can be found in .

export const environment = {
  production: true,
  apiBaseUrl: '/api/',
  language: 'nl',
  bugsnagKey: 'ea453401ecd1876f59b7baa93f6d48d9',

  matomoTrackerUrl: 'https://analytics.aimtofeel.com/',
  matomoSiteId: 2,  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as , .
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
